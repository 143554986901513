type ThemeModeType = 'light' | 'dark';

export const GREY = {
  0: '#FFFFFF',
  50: '#FAFAFA',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#ACACAC',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

export const PRIMARY = {
  lighter: '#90caf9',
  light: '#4fc3f7',
  main: '#1976d2',
  dark: '#42a5f5',
  darker: '#42a5f5',
  contrastText: '#fff',
};

const PRIMARY_DARK = {
  ...PRIMARY,
  contrastText: '#000',
};

export const SECONDARY = {
  lighter: '#f5f5f5',
  light: '#f3e5f5',
  main: '#9C27B0',
  dark: '#7B1FA2',
  darker: '#ab47bc',
  contrastText: '#fff',
};

export const INFO = {
  lighter: '#e1f5fe',
  light: '#4fc3f7',
  main: '#2196F3',
  dark: '#0288d1',
  darker: '#0288d1',
  contrastText: '#fff',
};

export const SUCCESS = {
  lighter: '#daecdc',
  light: '#c7efc9',
  main: '#2e7d32',
  dark: '#388e3c',
  darker: '#388e3c',
  contrastText: '#fff',
};

export const WARNING = {
  lighter: '#fff7ce',
  light: '#ffd600',
  main: '#ffa726',
  dark: '#f57c00',
  darker: '#ed6c02',
  contrastText: GREY[800],
};

export const WARNING_DARK = {
  lighter: '#d66d02',
  light: '#f57c00',
  main: '#ffa726',
  dark: '#ffd600',
  darker: '#fff7ce',
  contrastText: GREY[800],
};

export const ERROR = {
  lighter: '#ffe8e8',
  light: '#f2c7c7',
  main: '#f44336',
  dark: '#d32f2f',
  darker: '#ff0000',
  contrastText: '#fff',
};

export const ERROR_DARK = {
  lighter: '#edafaf',
  light: '#f2c7c7',
  main: '#f44336',
  dark: '#d32f2f',
  darker: '#ff0000',
  contrastText: '#fff',
};

const COMMON = {
  common: {
    black: '#000',
    white: '#fff',
    grey: '#ECEFF1',
    blueGrey: '#CFD8DC',
  },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
} as const;

const COMMON_DARK = {
  common: {
    black: '#000',
    white: '#fff',
    grey: '#000',
    blueGrey: '#000',
  },
  primary: PRIMARY_DARK,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING_DARK,
  error: ERROR_DARK,
  grey: GREY,
} as const;

export const palette = (themeMode: ThemeModeType) => {
  const light = {
    ...COMMON,
    mode: 'light',
  } as const;

  const dark = {
    ...COMMON_DARK,
    mode: 'dark',
  } as const;

  return themeMode === 'light' ? light : dark;
};

export default palette;
