import { ComponentProps, FC, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import bowser from 'bowser';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useScreen } from 'usehooks-ts';
import * as yup from 'yup';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import FeedbackTypeSwitch from './FeedbackTypeSwitch';
import { styles } from './styles';
import { FeedbackType, SendFeedbackFormType, SendFeedbackRequestType } from './types';
import { takeScreenshot } from '../../utils/takeScreenshot';
import { filesSizeValidation } from '../../validations/filesSizeValidation';
import { FormFilesSelect } from '../FormFilesSelect';
import { FormSelect } from '../FormSelect';
import { FormTextField } from '../FormTextField';

const MAX_FILES_TO_UPLOAD = 3;
const MAX_FILES_SIZE_IN_MB = 20;

const validationSchema = {
  type: yup.number().required(),
  message: yup.string().required("Обов'язкове поле").max(4000, 'Максимум 4000 символів'),
  contact: yup
    .string()
    .min(3, 'Мінімум 3 символи')
    .max(42, 'Максимум 42 символи')
    .matches(/^\+{0,1}[a-zA-Z0-9._]+$/, 'Може містити лише цифри, букви та знак підкреслення (_)')
    .matches(
      /^\+{0,1}[a-zA-Z0-9_]{3,}(\.[0-9]{2,9}){0,1}$/,
      'Не відповідає шаблону юзернейм в Signal',
    )
    .notRequired(),
  files: yup
    .array()
    .max(MAX_FILES_TO_UPLOAD, 'Максимум 3 файли')
    .test(
      'is-big-file',
      `Загальний розмір не повинен перевищувати ${MAX_FILES_SIZE_IN_MB}Mb`,
      filesSizeValidation(MAX_FILES_SIZE_IN_MB),
    )
    .notRequired(),
  module: yup.string().notRequired(),
};

const formDefaultValues: SendFeedbackFormType = {
  type: FeedbackType.other,
  message: '',
  contact: '',
  files: [],
  module: null,
};

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (props: SendFeedbackRequestType) => Promise<unknown>;
  modules?: ComponentProps<typeof FormSelect>['options'];
  rootElementSelector?: string;
  position?: 'center' | 'bottom-left' | 'bottom-right' | 'top-left' | 'top-right';
};

const FeedbackModal: FC<Props> = ({
  open = false,
  onClose,
  onSubmit,
  modules,
  rootElementSelector = '#root',
  position = 'bottom-left',
}) => {
  const [feedbackSent, setFeedbackSent] = useState(false);
  const [isSendFeedbackLoading, setIsSendFeedbackLoading] = useState(false);
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const screen = useScreen();

  if (modules?.length) {
    validationSchema.module = yup.string().required();
  }

  const formProps = useForm<Partial<SendFeedbackFormType>>({
    resolver: yupResolver(yup.object<SendFeedbackFormType>().shape(validationSchema)),
    defaultValues: formDefaultValues,
    mode: 'onChange',
  });
  const {
    handleSubmit,
    getValues,
    setValue,
    watch,
    reset,
    formState: { isValid },
  } = formProps;

  const handleClose = (_event: unknown, reason?: 'backdropClick') => {
    if (reason === 'backdropClick') return;
    setFeedbackSent(false);
    onClose();
    reset();
  };

  const getEnvironmentInfo = () => {
    const browser = bowser.parse(window.navigator.userAgent);
    return {
      ...browser,
      screen: {
        width: screen?.width,
        height: screen?.height,
        orientation: screen?.orientation,
      },
      path: window.location.href,
      themeMode: theme.palette.mode,
    };
  };

  const handleFormSubmit = handleSubmit(async (values: SendFeedbackFormType) => {
    const environment = getEnvironmentInfo();
    setIsSendFeedbackLoading(true);
    try {
      await onSubmit({ environment, ...values });
      setFeedbackSent(true);
    } catch (error) {
      const message = error.error?.response?.data?.extra?.details;
      console.error(message ? { message } : error);
    } finally {
      setIsSendFeedbackLoading(false);
    }
  });

  const handleTakeScreenshotClick = (files: File[], setFiles: (files: File[]) => void) => {
    const rootElement = document.querySelector(rootElementSelector) as HTMLUnknownElement;
    if (rootElement) {
      takeScreenshot(rootElement).then((blob: Blob) => {
        const file = new File([blob], `screenshot_${files.length + 1}.png`);
        setFiles([...files, file]);
      });
    }
  };

  watch('type');
  watch('files');
  const values = getValues();

  return (
    <>
      <Dialog
        sx={styles.modal}
        hideBackdrop={!isMobileView && position !== 'center'}
        open={open}
        onClose={handleClose}
        disableEscapeKeyDown={false}
        className={`position-${position}`}
      >
        {!feedbackSent && (
          <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
            Напишіть свій відгук{' '}
            <IconButton onClick={handleClose} sx={{ position: 'absolute', right: 12, top: 12 }}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
        )}
        <DialogContent>
          {!feedbackSent && (
            <FormProvider {...formProps}>
              <Box component="form" sx={styles.feedbackForm} onSubmit={handleFormSubmit}>
                <Controller
                  name="type"
                  render={({ field }) => {
                    return (
                      <FeedbackTypeSwitch
                        selected={field.value}
                        size={field.value !== null ? 'small' : 'middle'}
                        onChange={field.onChange}
                      />
                    );
                  }}
                />
                {values.type !== null && (
                  <>
                    {modules?.length && (
                      <FormSelect name="module" size="small" label="Модуль *" options={modules} />
                    )}
                    <FormTextField
                      name="message"
                      label="Повідомлення розробникам *"
                      variant="outlined"
                      size="small"
                      multiline
                      focused
                      rows={5}
                    />
                    <FormTextField
                      name="contact"
                      label="Зворотній зв'язок (номер або юзернейм в Signal)"
                      variant="outlined"
                      size="small"
                    />
                    <FormFilesSelect
                      name="files"
                      accept={['.png', '.jpeg', '.jpg']}
                      placeholder={
                        <Box textAlign="center">
                          <Typography variant="body2" color="text.disabled">
                            Виберіть або перетягніть файл
                          </Typography>
                          <CloudUploadOutlinedIcon
                            sx={{ width: 44, height: 44, color: 'text.disabled' }}
                          />
                          <Typography variant="body2" color="text.disabled">
                            <span>JPG, JPEG, PNG</span>
                          </Typography>
                        </Box>
                      }
                      maxLength={MAX_FILES_TO_UPLOAD}
                      previewMode="preview"
                      disabledActions={values.files.length >= MAX_FILES_TO_UPLOAD}
                      customActions={
                        <Button
                          disabled={values.files.length >= MAX_FILES_TO_UPLOAD}
                          variant="outlined"
                          onClick={() => {
                            handleTakeScreenshotClick(values.files, (files) =>
                              setValue('files', files),
                            );
                          }}
                        >
                          Скріншот
                        </Button>
                      }
                    />
                    <Button
                      disabled={!isValid || isSendFeedbackLoading}
                      onClick={handleFormSubmit}
                      type="submit"
                      variant="contained"
                    >
                      {isSendFeedbackLoading && <CircularProgress size={24} thickness={2} />}
                      {!isSendFeedbackLoading && 'Відправити'}
                    </Button>
                  </>
                )}
              </Box>
            </FormProvider>
          )}

          {feedbackSent && (
            <Box sx={styles.feedbackForm}>
              <Paper sx={styles.successMessage}>
                <CheckCircleIcon sx={styles.successMessageIcon} />
                <Typography variant="body1" fontWeight={600}>
                  Дякуємо за ваш відгук!
                </Typography>
                <Typography variant="body2">
                  Ми зв'яжемось з вами, якщо будуть додаткові питання
                </Typography>
              </Paper>

              <Button onClick={handleClose} variant="contained">
                Закрити
              </Button>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export { FeedbackModal };
