import { FC } from 'react';

import { Card, CardProps, SxProps } from '@mui/material';

import { AvatarPlaceholder } from 'components/AvatarPlaceholder';
import { AvatarInfo } from 'types/dossier.type';

import AvatarCardToolbar from './components/AvatarCardToolbar';
import AvatarMedia from './components/AvatarMedia';
import { styles } from './styles';

type Props = {
  avatarInfo: Omit<AvatarInfo, 'id'> | { photo: string; isVideo?: boolean } | null;
  width?: number | '100%';
  hideToolbar?: boolean;
  sx?: CardProps['sx'];

  onClick?(): void;
};

export const AvatarCard: FC<Props> = ({
  avatarInfo = null,
  width = '100%',
  hideToolbar = false,
  sx,

  onClick = () => {},
}) => {
  const link = avatarInfo && 'link' in avatarInfo ? avatarInfo.link : avatarInfo?.photo;

  return (
    <Card
      sx={[styles.card, { width }, sx] as SxProps}
      onClick={onClick}
      data-testid="avatar-card-control"
    >
      {!link && <AvatarPlaceholder />}
      {link && avatarInfo && <AvatarMedia photo={link} isVideo={avatarInfo.isVideo ?? false} />}
      {!hideToolbar &&
        avatarInfo &&
        'updatedAt' in avatarInfo &&
        avatarInfo.updatedAt &&
        !avatarInfo.isExists && <AvatarCardToolbar updatedAt={avatarInfo.updatedAt} />}
    </Card>
  );
};
