export enum LocationsView {
  TRAJECTORY = 'trajectory',
  BY_DATE = 'byDate',
}

export enum LocationsSource {
  TELEGRAM = 'telegram',
  MAMBA = 'mamba',
}

export enum CoordinateSystem {
  WGS84 = 'WGS84',
  MGRS = 'MGRS',
  UCS2000 = 'UCS2000',
  SK42 = 'SK42',
}
