import { useState, useCallback } from 'react';

export const useExpanded = (
  initialExpanded: boolean,
  totalItems: number,
  defaultItemsInView: number
) => {
  const [expanded, setExpanded] = useState(initialExpanded);

  const itemsToShow = expanded ? totalItems : defaultItemsInView;
  const showMoreBtn = totalItems > defaultItemsInView;
  const count = totalItems - defaultItemsInView;

  const toggleExpanded = useCallback(() => {
    setExpanded((prevExpanded) => !prevExpanded);
  }, []);

  return { expanded, itemsToShow, showMoreBtn, count, toggleExpanded };
};
