import { skipToken } from '@reduxjs/toolkit/dist/query';

import { useGetDossierConnectorListQuery } from 'api/services/dossier';
import { DossierSocialConnectorId as SocialConnectorId } from 'enums/dossier';
import { DossierConnector } from 'types/dossier.type';

type UseBandConnectorsReturnType = {
  connectors: DossierConnector[];
  isConnectorsLoading: boolean;
};

export const useBandConnectors = (
  bandIdOrSkipToken: number | typeof skipToken,
  connectorId: SocialConnectorId
): UseBandConnectorsReturnType => {
  const { data: connectorsData, isLoading: isConnectorsLoading } =
    useGetDossierConnectorListQuery(bandIdOrSkipToken);

  const connectors = (connectorsData?.results ?? []).filter(
    (connector) => connector.connectorType.id === connectorId
  );

  return { connectors, isConnectorsLoading };
};
