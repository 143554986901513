import { FC } from 'react';

import dayjs from 'dayjs';

import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined';
import { Box, Typography, Stack } from '@mui/material';

import { DATE_TIME_FORMAT } from 'constants/date';
import { Styles } from 'types/theme.type';

const styles: Styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 10,
    width: '100%',
    p: '2px 4px',
    bgcolor: '#0000008f',
  },
  typography: {
    color: 'common.white',
    fontSize: 10,
    fontWeight: 600,
  },
};

type Props = {
  updatedAt: string | Date | null | undefined;
};

export const AvatarCardToolbar: FC<Props> = ({ updatedAt }) => {
  const updatedDate = dayjs(updatedAt).format(DATE_TIME_FORMAT);
  const title = `Видалення помічено: ${updatedDate}`;

  return (
    <Box sx={styles.container} title={title}>
      <BrokenImageOutlinedIcon fontSize="small" color="error" />
      <Stack>
        <Typography variant="body1" noWrap color="white" sx={styles.typography}>
          Видалене
        </Typography>
        <Typography variant="body1" noWrap sx={styles.typography}>
          {updatedDate}
        </Typography>
      </Stack>
    </Box>
  );
};
