import 'dayjs/locale/uk';
import { AuthProvider } from '@ornament/ui';
import * as Sentry from '@sentry/react';
import { Provider as ReduxProvider } from 'react-redux';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import Notification from 'components/Alert';
import AppRouter from 'components/AppRouter';
import { AuthContextProvider } from 'contexts/AuthContext/AuthContextProvider';
import ModalProvider from 'contexts/ModalContext';
import { SettingsContextProvider } from 'contexts/SettingsContext';
import { ThemeProvider } from 'contexts/ThemeContext';
import ErrorPage from 'ErrorPage';
import store from 'store';

const App = () => {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorPage />}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ReduxProvider store={store}>
          <ThemeProvider>
            <ModalProvider>
              <AuthProvider>
                <AuthContextProvider>
                  <SettingsContextProvider>
                    <AppRouter />
                  </SettingsContextProvider>
                </AuthContextProvider>
              </AuthProvider>
            </ModalProvider>
            <Notification />
          </ThemeProvider>
        </ReduxProvider>
      </LocalizationProvider>
    </Sentry.ErrorBoundary>
  );
};

export default App;
