export enum MapVariant {
  mapbox = 'mapbox',
  leaflet = 'leaflet',
}

export enum Modes {
  point = 'point',
  algo = 'algo',
  polygon = 'polygon',
}

export enum MapStyles {
  streets = 'streets',
  satellite = 'satellite',
  arcgison = 'arcgison',
}

export enum MapboxDataLayerIds {
  deepState = 'Deepstate',
  deepstateZones = 'Deepstate Polygons',
  deepstatePoints = 'Deepstate Points',
  deepstateTrenches = 'Deepstate Trenches',
  grid = 'grid',
  NAILabels = 'NAI labels',
  NAIPolygons = 'NAI polygons',
}
