import { useEffect, useState } from 'react';

import { useLazyGetPhotoS3UrlQuery } from 'api/services/photos';
import { APP_BE_URL } from 'constants/appBeUrl';

export const useS3ProxyUrl = (
  url: string | undefined
): [string | undefined, { isLoading: boolean }] => {
  const [avatarPreparedLink, setAvatarPreparedLink] = useState<string | undefined>();

  const [getPhotoS3Url, { isLoading }] = useLazyGetPhotoS3UrlQuery();

  useEffect(() => {
    if (url?.startsWith('/')) {
      url = `${APP_BE_URL}${url}`;
    }
    if (APP_BE_URL && url?.startsWith(APP_BE_URL)) {
      getPhotoS3Url({ url }).then((data) => {
        setAvatarPreparedLink(data.url);
      });
    } else {
      setAvatarPreparedLink(url);
    }
  }, [url]);

  return [avatarPreparedLink, { isLoading }];
};
