import { api } from 'api';

import { endpoints } from '../endpoints/auth';

const authApi = api.injectEndpoints({
  endpoints,
});

export const {
  useGetMfaStatusQuery,
  useGetMfaRecoveryCodesQuery,
  useSignInMutation,
  useSignOutMutation,
  useVerifyMfaCodeMutation,
  useCreateMfaMutation,
  useDeleteMfaMutation,

  useGetTransactionToKcLoginFlagsQuery,
} = authApi;
