import { FC, PropsWithChildren, createContext, useMemo, useContext, useEffect } from 'react';

import { useLocalStorage } from 'usehooks-ts';

import { MapVariant } from 'components/Map/enums';
import { THEME_MODE_STORAGE_KEY } from 'contexts/ThemeContext/constants';
import { ThemeMode } from 'contexts/ThemeContext/types';
import { CoordinateSystem } from 'enums/locations';
import { PlausibleEvents } from 'enums/plausibleAnalytics';
import { usePlausibleAnalytics } from 'hooks';

import { SettingsType } from './types';

const settingsStorageKeys = {
  mapVariant: 'settings.map.variant',
  coordinateSystem: 'settings.coordinateSystem',
  themeMode: THEME_MODE_STORAGE_KEY,
  photosDefaultCollapsed: 'settings.photosDefaultCollapsed',
};

const defaultSettings: SettingsType = {
  map: { variant: MapVariant.mapbox },
  coordinateSystem: CoordinateSystem.WGS84,
  theme: { mode: ThemeMode.light },
  photosDefaultCollapsed: false,
};

type SettingsContextType = {
  settings: SettingsType;
  setSettings: (value: Partial<SettingsType>) => void;
  isLoading: boolean;
};

const SettingsContext = createContext<SettingsContextType>({
  settings: defaultSettings,
  setSettings: () => {},
  isLoading: false,
});

export const COORDINATE_SYSTEM_OPTIONS = [
  {
    value: CoordinateSystem.WGS84,
    label: 'WGS84',
  },
  {
    value: CoordinateSystem.MGRS,
    label: 'MGRS',
  },
  {
    value: CoordinateSystem.UCS2000,
    label: 'УСК-2000',
  },
];

export const SettingsContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [coordinateSystem, setCoordinateSystem] = useLocalStorage(
    settingsStorageKeys.coordinateSystem,
    defaultSettings.coordinateSystem
  );
  const [mapVariant, setMapVariant] = useLocalStorage(
    settingsStorageKeys.mapVariant,
    defaultSettings.map.variant
  );

  const [themeMode, setThemeMode] = useLocalStorage(settingsStorageKeys.themeMode, ThemeMode.light);
  const [photosDefaultCollapsed, setPhotosDefaultCollapsed] = useLocalStorage(
    settingsStorageKeys.photosDefaultCollapsed,
    false
  );

  const { plausibleEvent } = usePlausibleAnalytics();

  const value = useMemo(
    () => ({
      settings: {
        map: { variant: mapVariant },
        coordinateSystem,
        theme: { mode: themeMode },
        photosDefaultCollapsed,
      },
      setSettings(newValue: Partial<SettingsType>) {
        if (newValue?.coordinateSystem) {
          setCoordinateSystem(newValue.coordinateSystem);
        }
        if (newValue.map?.variant) {
          setMapVariant(newValue.map.variant);
        }
        if (newValue.theme?.mode) {
          setThemeMode(newValue.theme?.mode);
        }
        if (newValue.photosDefaultCollapsed !== undefined) {
          setPhotosDefaultCollapsed(newValue.photosDefaultCollapsed);
        }
      },
      isLoading: false,
    }),
    [mapVariant, coordinateSystem, themeMode, photosDefaultCollapsed]
  );

  useEffect(() => {
    plausibleEvent(PlausibleEvents.SETTINGS_COLLAPSE_PHOTOS_SWITCH, {
      props: { value: `${photosDefaultCollapsed ? 'collapsed' : 'expanded'}` },
    });

    plausibleEvent(PlausibleEvents.SETTINGS_THEME_MODE, {
      props: { mode: themeMode },
    });
  }, []);

  return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>;
};

export const useSettingsContext = () => useContext(SettingsContext);
