import { FC } from 'react';

import NoAccountsIcon from '@mui/icons-material/NoAccounts';

type Props = Partial<{
  width: number | string;
  height: number | string;
  borderRadius: number | string;
}>;

export const AvatarPlaceholder: FC<Props> = ({ width = '100%', height = 'auto', borderRadius }) => {
  return (
    <NoAccountsIcon
      sx={{
        width,
        height,
        display: 'block',
        objectFit: 'cover',
        color: '#f1f1f1',
        background: '#ddd',
        borderRadius,
      }}
    />
  );
};
