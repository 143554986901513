import { skipToken } from '@reduxjs/toolkit/dist/query';

import { useGetDossierConnectorListQuery } from 'api/services/dossier';
import { DossierSocialConnectorId as SocialConnectorId } from 'enums/dossier';
import { DossierConnector } from 'types/dossier.type';

type UseConnectorReturnType = {
  connector: DossierConnector | undefined;
  isConnectorsLoading: boolean;
};

export const useConnector = (
  bandId: number | undefined,
  connectorId: SocialConnectorId
): UseConnectorReturnType => {
  const { data: connectorsData, isLoading: isConnectorsLoading } = useGetDossierConnectorListQuery(
    bandId ?? skipToken
  );

  const connector = connectorsData?.results.find(
    (connector) => connector.connectorType.id === connectorId
  );

  return { connector, isConnectorsLoading };
};
