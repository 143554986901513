import { Styles } from 'types/theme.type';

export const styles: Styles = {
  card: {
    display: 'flex',
    maxWidth: '100%',
    flexDirection: 'column',
    '&.MuiPaper-elevation': {
      boxShadow: 'none',
    },
  },
};
