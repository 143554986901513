import { FC } from 'react';

import { TextField } from '@mui/material';

import { UseManageUserTagsReturnType } from '../../types';

export const FIELD_TEST_ID = 'search-field';

type SearchFieldProps = {
  value: UseManageUserTagsReturnType['searchValue'];
  onChange: UseManageUserTagsReturnType['handleInputChange'];
};

export const SearchField: FC<SearchFieldProps> = ({ value, onChange }) => (
  <TextField
    data-testid={FIELD_TEST_ID}
    autoFocus
    fullWidth
    label="Пошук тегів"
    type="search"
    size="small"
    value={value}
    onChange={onChange}
  />
);
