import type { SxProps, Theme } from '@mui/material';

import { isDarkMode } from '../../utils/isDarkMode';

export const styles: Record<string, NonNullable<SxProps<Theme>>> = {
  modal: {
    '& .MuiDialog-container .MuiDialog-paper': (theme) => ({
      m: 2,
      [theme.breakpoints.up('sm')]: {
        position: 'absolute',
      },
    }),
    '&.position-bottom-left .MuiDialog-container .MuiDialog-paper': (theme) => ({
      [theme.breakpoints.up('sm')]: { bottom: 0, left: 0 },
    }),
    '&.position-bottom-right .MuiDialog-container .MuiDialog-paper': (theme) => ({
      [theme.breakpoints.up('sm')]: { bottom: 0, right: 0 },
    }),
    '&.position-top-left.MuiDialog-container .MuiDialog-paper': (theme) => ({
      [theme.breakpoints.up('sm')]: { top: 0, left: 0 },
    }),
    '&.position-top-right.MuiDialog-container .MuiDialog-paper': (theme) => ({
      [theme.breakpoints.up('sm')]: { top: 0, right: 0 },
    }),
  },
  feedbackForm: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    maxWidth: 332,
    [theme.breakpoints.up('sm')]: {
      width: 332,
    },
  }),
  successMessage: {
    px: 1,
    py: 2,
    textAlign: 'center',
    backgroundColor: isDarkMode('', 'grey.200'),
  },
  successMessageIcon: { width: 40, height: 40, color: 'success.main' },
};
