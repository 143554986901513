import type { SxProps, Theme } from '@mui/material/styles';

export const CardWrapperStyle: SxProps<Theme> = {
  position: 'relative',
  display: 'flex',
  width: '100%',
  height: '100%',
  cursor: 'pointer',
  zIndex: 1,
  'img, video': {
    maxWidth: '100%',
  },
  '&.MuiPaper-elevation': { boxShadow: 'none' },
  img: {
    objectFit: 'contain',
    backgroundColor: 'common.black',
    aspectRatio: '1/1',
  },
};

export const IconPlayStyle: SxProps<Theme> = {
  position: 'absolute',
  width: '50%',
  height: '50%',
  top: '25%',
  left: '25%',
  color: '#000',
  backgroundColor: '#fff',
  borderRadius: '50%',
  opacity: '0.7',
  zIndex: 10,
};
