import { ComponentProps, FC } from 'react';

import dayjs from 'dayjs';
import { Controller } from 'react-hook-form';

import { FormControl, FormHelperText } from '@mui/material';

import { DateTimePicker } from '../DateTimePicker';
import { FormErrorMessage } from '../FormErrorMessage';

type Props = ComponentProps<typeof DateTimePicker> & {
  name: string;
  format?: string;
  future?: boolean;
  size?: 'small' | 'medium';
  showErrors?: boolean;
  multipleErrors?: boolean;
};

export const FormDateTimePickerField: FC<Props> = ({
  name,
  label,
  slotProps,
  multipleErrors,
  future = true,
  size = 'small',
  showErrors = true,
  onChange,
  ...rest
}) => {
  return (
    <Controller
      name={name}
      render={({
        field: { onChange: cOnChange, value: cValue },
        fieldState: { invalid, error },
      }) => {
        const date = cValue && dayjs(cValue);
        const validationMessage = showErrors ? (
          <FormErrorMessage error={error} multiple={multipleErrors} />
        ) : null;
        return (
          <FormControl component="fieldset" error={invalid} fullWidth>
            <DateTimePicker
              {...rest}
              value={date || null}
              onChange={(event, context) => {
                onChange?.(event, context);
                cOnChange(event);
              }}
              disableFuture={!future}
              label={label}
              slotProps={{
                ...slotProps,
                textField: { size, error: invalid, ...slotProps?.textField },
                actionBar: { actions: ['clear'], ...slotProps?.actionBar },
              }}
            />
            {invalid && <FormHelperText error>{validationMessage}</FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
};
