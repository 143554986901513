import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

const useBackButton = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handlePopstate = () => navigate(-1);

    window.addEventListener('popstate', handlePopstate);
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, [navigate]);
};

export default useBackButton;
