import { FC } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  typographyClasses,
  type SxProps,
} from '@mui/material';

import FilePreview from './FilePreview';
import { getSizeLabel } from './utils';

const styles: Record<string, SxProps> = {
  filesPreviewList: {
    padding: 0,
    maxHeight: 172,
    overflowY: 'auto',
    '& li': { padding: 0 },
    '& li .MuiTypography-root': {
      fontSize: 14,
      color: 'text.secondary',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    [`.file-error .${typographyClasses.root}`]: {
      color: 'error.main',
    },
  },
  filesPreviewBox: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 1,
    '.file-error': {
      border: '2px solid',
      borderColor: 'error.main',
    },
  },
};

type Props = {
  files: File[];
  previewMode: 'preview' | 'list';
  errors?: { name: string; text: string }[] | null;
  disabled?: boolean;
  onDelete?: (file: File) => void;
};

const FilePreviewList: FC<Props> = ({
  files,
  previewMode,
  errors = null,
  disabled = false,
  onDelete = () => {},
}) => {
  if (previewMode === 'preview') {
    return (
      <Box sx={styles.filesPreviewBox}>
        {files.map((file, index) => {
          const error = errors?.find((error) => error.name === file.name);
          return (
            <FilePreview
              className={error ? 'file-error' : undefined}
              key={`${file.name}${file.size}${index}`}
              file={file}
              onDelete={onDelete}
              disabled={disabled}
              errorText={error?.text}
            />
          );
        })}
      </Box>
    );
  }

  return (
    <List sx={styles.filesPreviewList}>
      {files.map((file, index) => {
        const error = errors?.find((error) => error.name === file.name);
        return (
          <ListItem key={`${file.name}${file.size}${index}`} title={error?.text}>
            <ListItemText className={error ? 'file-error' : undefined}>
              {file.name} ({getSizeLabel(file.size)})
            </ListItemText>
            <IconButton size="small" onClick={() => onDelete(file)} disabled={disabled}>
              <CloseIcon />
            </IconButton>
          </ListItem>
        );
      })}
    </List>
  );
};

export default FilePreviewList;
