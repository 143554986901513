export const checkSiteAccess = ({ url, timeout = 10_000 }: { url: string; timeout?: number }) => {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort('Timeout error.'), timeout);
  return fetch(url, {
    signal: controller.signal,
    redirect: 'follow',
    mode: 'no-cors',
  })
    .then(() => {
      clearTimeout(timeoutId);
      return true;
    })
    .catch(() => false);
};
