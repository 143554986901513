import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SearchByNumbersProps } from 'types/numbers.type';
import { BaseSearchData } from 'types/searches.type';

import { BreadcrumbsState } from './type';

const initialState: BreadcrumbsState = {
  list: [],
};

const slice = createSlice({
  name: 'breadcrumbs',
  initialState,
  reducers: {
    setLocationBreadcrumbs(state, { payload: location }: PayloadAction<BaseSearchData>) {
      if (state.list.find((item) => item.id === location.id)) return state;
      return {
        ...state,
        list: [...state.list, { id: location.id, area: 'locations', name: location.name }],
      };
    },
    setScheduledBreadcrumbs(state, { payload: scheduled }: PayloadAction<BaseSearchData>) {
      if (state.list.find((item) => item.id === scheduled.areaId)) return state;
      return {
        ...state,
        list: [
          ...state.list,
          {
            id: scheduled.areaId,
            area: 'scheduled',
            name: scheduled.name,
            description: scheduled.description,
          },
        ],
      };
    },
    setNumbersBreadcrumbs(state, { payload }: PayloadAction<SearchByNumbersProps>) {
      if (state.list.find((item) => item.id === payload.id)) return state;
      return {
        ...state,
        list: [...state.list, { id: payload.id, area: 'numbers', name: payload.name }],
      };
    },
  },
});

export const { setLocationBreadcrumbs, setScheduledBreadcrumbs, setNumbersBreadcrumbs } =
  slice.actions;

export default slice.reducer;
