import { PRIMARY, INFO, ERROR, SUCCESS, WARNING } from 'contexts/ThemeContext/palette';
import { DossierSocialConnectorId } from 'enums/dossier';
import {
  SearchStatus,
  SearchRequestStatus,
  MarkerForSearch,
  ProcessingStatus,
  VKSearchRequestStatus,
} from 'enums/searches';
import { MarkerDataProps } from 'types/searches.type';

export const CRONTAB_DEFAULT = '0 1,12 * * *';
export const POLLING_INTERVAL = 45_000 as const;
export const MAX_DISTANCE = 50_000 as const;
export const NAME_IS_HIDDEN = '<назву приховано>' as const;

export const NUMBERS_STATUS_COLOR_MAP = {
  [ProcessingStatus.NOT_PROCESSED]: PRIMARY.main,
  [ProcessingStatus.IN_PROGRESS]: INFO.main,
  [ProcessingStatus.READY]: SUCCESS.main,
  [ProcessingStatus.CANCELLED]: WARNING.main,
  [ProcessingStatus.CANCELLING]: WARNING.main,
};

export const VK_NUMBERS_STATUS_COLOR_MAP = {
  [VKSearchRequestStatus.NEW]: PRIMARY.main,
  [VKSearchRequestStatus.IN_PROGRESS]: INFO.main,
  [VKSearchRequestStatus.DONE]: SUCCESS.main,
  [VKSearchRequestStatus.CANCELLED]: WARNING.main,
  [VKSearchRequestStatus.ERROR]: ERROR.main,
};

export const VKSearchRequestStatusToSearchStatus = {
  NEW: SearchRequestStatus.NEW,
  IN_PROGRESS: SearchRequestStatus.PROCESSING,
  DONE: SearchRequestStatus.DONE,
  CANCELLED: SearchRequestStatus.CANCELLED,
  ERROR: SearchRequestStatus.ERROR,
} as const;

export const VKSearchRequestStatusToSearchStatusTitle = {
  [VKSearchRequestStatus.NEW]: SearchStatus.CREATED,
  [VKSearchRequestStatus.IN_PROGRESS]: SearchStatus.PROCESSING,
  [VKSearchRequestStatus.DONE]: SearchStatus.READY,
  [VKSearchRequestStatus.CANCELLED]: SearchStatus.CANCELLED,
  [VKSearchRequestStatus.ERROR]: SearchStatus.ERROR,
} as const;

export const SEARCH_STATUS_COLOR_MAP = {
  [SearchRequestStatus.NEW]: PRIMARY.main,
  [SearchRequestStatus.QUEUE]: WARNING.main,
  [SearchRequestStatus.PROCESSING]: INFO.main,
  [SearchRequestStatus.DONE]: SUCCESS.main,
  [SearchRequestStatus.DOWNLOADED]: SUCCESS.main,
  [SearchRequestStatus.ERROR]: ERROR.main,
  [SearchRequestStatus.ERROR_NO_SUCH_CHANNEL]: ERROR.main,
  [SearchRequestStatus.ERROR_CHANNEL_WITHOUT_COMMENTS]: ERROR.main,
  [SearchRequestStatus.CANCELLING]: WARNING.main,
  [SearchRequestStatus.CANCELLED]: WARNING.main,
};

export const SEARCH_MARKER_OPTIONS: MarkerDataProps[] = [
  {
    id: MarkerForSearch.RED,
    colorIcon: 'error',
    colorHex: ERROR.lighter,
  },
  {
    id: MarkerForSearch.YELLOW,
    colorIcon: 'warning',
    colorHex: WARNING.lighter,
  },
  {
    id: MarkerForSearch.GREEN,
    colorIcon: 'success',
    colorHex: SUCCESS.lighter,
  },
  {
    id: MarkerForSearch.NULLISH,
    colorIcon: 'action',
    colorHex: '#ffffff',
  },
];

export const SEARCH_MARKER_OPTIONS_DARK: MarkerDataProps[] = [
  {
    id: MarkerForSearch.RED,
    colorIcon: 'error',
    colorHex: ERROR.main,
  },
  {
    id: MarkerForSearch.YELLOW,
    colorIcon: 'warning',
    colorHex: WARNING.main,
  },
  {
    id: MarkerForSearch.GREEN,
    colorIcon: 'success',
    colorHex: SUCCESS.dark,
  },
  {
    id: MarkerForSearch.NULLISH,
    colorIcon: 'action',
    colorHex: '#000000',
  },
];

export const SEARCH_MARKER_COLOR_MAP = {
  [MarkerForSearch.RED]: 'error.lighter',
  [MarkerForSearch.YELLOW]: 'warning.lighter',
  [MarkerForSearch.GREEN]: 'success.lighter',
} as const;

export const SEARCH_MARKER_COLOR_MAP_DARK = {
  [MarkerForSearch.RED]: 'error.dark',
  [MarkerForSearch.YELLOW]: 'warning.lighter',
  [MarkerForSearch.GREEN]: 'success.dark',
} as const;

export const OPTIONS_SEARCH_STATUS = [
  { value: SearchRequestStatus.NEW, label: SearchStatus.CREATED },
  { value: SearchRequestStatus.QUEUE, label: SearchStatus.QUEUED },
  { value: SearchRequestStatus.PROCESSING, label: SearchStatus.PROCESSING },
  { value: SearchRequestStatus.DONE, label: SearchStatus.READY },
  { value: SearchRequestStatus.ERROR, label: SearchStatus.ERROR },
  { value: SearchRequestStatus.CANCELLED, label: SearchStatus.CANCELLED },
];

export const SEARCH_RESULTS_EMPTY_STATE: Record<SearchRequestStatus, string> = {
  [SearchRequestStatus.NEW]: 'Сканування триває, очікуйте',
  [SearchRequestStatus.QUEUE]: 'Сканування триває, очікуйте',
  [SearchRequestStatus.PROCESSING]: 'Сканування триває, очікуйте',
  [SearchRequestStatus.DONE]: 'На жаль, нічого не знайшлося',
  [SearchRequestStatus.CANCELLED]: 'Сканування відмінено',
  [SearchRequestStatus.CANCELLING]: 'Сканування відміняється',
  [SearchRequestStatus.ERROR]: 'При скануванні відбулася помилка',
  [SearchRequestStatus.DOWNLOADED]: '',
  [SearchRequestStatus.ERROR_NO_SUCH_CHANNEL]: '',
  [SearchRequestStatus.ERROR_CHANNEL_WITHOUT_COMMENTS]: '',
};

export const PROCESSING_STATUS_OPTIONS = [
  {
    value: ProcessingStatus.NOT_PROCESSED,
    label: 'Не опрацьовано',
  },
  {
    value: ProcessingStatus.IN_PROGRESS,
    label: 'В роботі',
  },
  {
    value: ProcessingStatus.READY,
    label: 'Опрацьовано',
  },
];

export const DEFAULT_TABLE_CONFIG = {
  enableStickyHeader: true,
  enableSorting: false,
  enableFilters: false,
  enableColumnFilters: false,
  enableColumnActions: false,
  enableDensityToggle: false,
  enableExpanding: true,
  enableExpandAll: false,
  enableFullScreenToggle: false,
  manualExpanding: true,
  manualPagination: true,
  localization: {
    rowsPerPage: 'Результатів на сторінці',
    hideAll: 'Приховати все',
    showAll: 'Показати все',
    noRecordsToDisplay: 'Нічого не знайдено',
  },
  defaultColumn: {
    minSize: 20,
    maxSize: 350,
    size: 100,
  },
};

export const PAGINATION_PARAMS = {
  page: 1,
  pageSize: 25,
  pageSizeItems: [10, 25, 50, 100],
} as const;

export const FiltersBottomPanelStyle = {
  position: 'relative',
  zIndex: 10,
  display: 'flex',
  gap: 2,
  ml: 1,
  mt: 2,
  mb: '-40px',
};

export const DISTANCE_OPTIONS = [
  500, 1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 11000, 12000,
] as const;

export const MIN_GRID_POINTS = 2;

export const POINTS_COUNT_ERROR = 1018;

export enum ACCURACY {
  EXACT = 'exact',
  CENTROID = 'centroid',
}

export const LOCATIONS_SOURCE = {
  mamba: DossierSocialConnectorId.MAMBA,
  telegram: DossierSocialConnectorId.TELEGRAM,
};
