import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LayoutState } from 'types/layout.type';

const parseMandalaCoords = () => {
  const param = new URLSearchParams(window.location.search).get('mandalaCoords') ?? undefined;
  if (!param) {
    return undefined;
  }

  const [lat, lng] = param.split(',');
  return [parseFloat(lat), parseFloat(lng)];
};

const initialState: LayoutState = {
  menuOpen: false,
  mandalaCoords: parseMandalaCoords(),
};

const slice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    update(state, { payload: changes }: PayloadAction<Partial<LayoutState>>) {
      return { ...state, ...changes };
    },

    setMandalaCoords(state) {
      return { ...state, mandalaCoords: parseMandalaCoords() };
    },
  },
});

export const { update: layoutUpdate } = slice.actions;

export default slice.reducer;
