import { createSvgIcon } from '@mui/material';

const UnPin = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_733_5195"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="currentColor" />
    </mask>
    <g mask="url(#mask0_733_5195)">
      <path
        d="M17 3V5H16V13.175L14 11.175V5H10V7.175L7 4.175V3H17ZM12 23L11 22V16H6V14L8 12V10.85L1.4 4.2L2.8 2.8L21.2 21.2L19.75 22.6L13.15 16H13V22L12 23ZM8.85 14H11.15L10 12.85L8.85 14Z"
        fill="currentColor"
        fill-opacity="0.56"
      />
    </g>
  </svg>,
  'unpin',
);

export default UnPin;
