import { ComponentProps, FC, ReactElement } from 'react';

import { useField } from 'formik';

import { FormControlLabel, Checkbox } from '@mui/material';

type Props = Omit<ComponentProps<typeof Checkbox>, 'name'> & {
  name: string;
  label: string | ReactElement;
};

const FormikCheckbox: FC<Props> = ({ name, label, ...props }) => {
  const [field] = useField({ name, type: 'checkbox' });

  return (
    <FormControlLabel
      sx={{ margin: 0 }}
      control={
        <Checkbox
          {...field}
          {...props}
          value={field.value || false}
          checked={field.checked}
          inputProps={{ ...props.inputProps, type: 'checkbox' }}
        />
      }
      label={label}
    />
  );
};

export default FormikCheckbox;
