export enum LoadingStates {
  IDLE = 'idle',
  PENDING = 'pending',
  FULFILLED = 'fulfilled',
  REJECTED = 'rejected',
}

export enum OnlineStatuses {
  ONLINE = 0,
  OFFLINE = 1,
  RECENTLY = 2,
  LAST_WEEK = 3,
  LAST_MONTH = 4,
  EMPTY = 5,
}
