import { useEffect, useMemo } from 'react';

import { useConvertCoordinatesQuery } from 'api/services/geocoding';
import { useSettingsContext } from 'contexts/SettingsContext';
import { CoordinateSystem } from 'enums/locations';
import { ConvertCoordinatesResponse } from 'types/geocoding.type';
import { displayErrorMessages } from 'utils';
import { checkIsCoordsValid, formatCoordinates } from 'utils/geo';

export const useConvertCoordinates = (
  coordinates: string[] = [],
  systemTo: CoordinateSystem | undefined = undefined
) => {
  const { settings } = useSettingsContext();

  const coordinateSystem = systemTo ?? settings.coordinateSystem;

  const isSkip = useMemo(() => {
    const { isCoordsValid, type } = checkIsCoordsValid(coordinates[0]);
    return !isCoordsValid || type === coordinateSystem;
  }, [coordinates, settings, coordinateSystem]);

  const { data, isError, error } = useConvertCoordinatesQuery(
    {
      coords: coordinates,
      systemTo: coordinateSystem,
    },
    { skip: isSkip }
  );

  useEffect(() => {
    if (isError) displayErrorMessages(error);
  }, [isError, error]);

  const { coords = [] } = data || ({} as ConvertCoordinatesResponse);

  const convertedCoordinates: string[] = useMemo(
    () => (isSkip ? coordinates.map(formatCoordinates) : coords.map(formatCoordinates)),
    [isSkip, coordinates, coords]
  );

  return convertedCoordinates;
};
