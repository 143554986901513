import { MimeType } from './types';
import { sizeInMb } from '../../utils/sizeInMb';

export const fileToDataUrl: (file: File) => Promise<string> = (file) => {
  if (file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const imageDataUrl = reader.result as string;
        if (imageDataUrl) {
          resolve(imageDataUrl);
        } else {
          reject(new Error('File conversion error'));
        }
      };
      reader.readAsDataURL(file);
    });
  }
  return Promise.resolve('');
};

export const filterAccept = (accept: MimeType[] | string[]) => {
  const matchType = accept.join('|');
  const matchName = `(${matchType.replace('.', '\\.')})$`;
  return (file: File) => file.type?.match(matchType) || file.name?.match(matchName);
};

export const getSizeLabel = (size: number) => {
  if (size < 500 * 1024) {
    return `${(size / 1024).toFixed(1)} Kb`;
  }
  return `${sizeInMb(size).toFixed(1)} Mb`;
};
