import dayjs from 'dayjs';

import { normalizeSearchParamsSchema } from './normalizeSearchParamsSchema';
import { ParsedQueryParam } from '../types/ParsedQueryParam';
import { SearchQueryParam } from '../types/SearchQueryParam';
import { SearchQueryParamSchema } from '../types/SearchQueryParamSchema';

export const ONLY_DIGITS_PATTERN = /^\s*\d+\s*$/;

const castValue = <T extends object>(value: string, schema: SearchQueryParam<T>) => {
  const { type } = schema;

  switch (type) {
    case 'array':
      return value !== ''
        ? value.split(',').map((item) => {
            if (ONLY_DIGITS_PATTERN.test(item)) {
              return Number(item);
            }
            return item.trim();
          })
        : [];
    case 'boolean':
      return /(true|on)/i.test(value.trim());
    case 'date':
    case 'datetime':
      return value !== '' ? dayjs(value).format() : '';
    case 'number':
      if (value && /^\d+$/.test(value)) {
        return Number(value);
      }
      break;
    default:
      return value ?? '';
  }

  return undefined;
};

export const getSearchParams = <T extends object>(
  searchParams: URLSearchParams,
  schema: SearchQueryParam<T>[] | SearchQueryParamSchema<T>,
) => {
  const parsedParams: ParsedQueryParam = {};

  const schemaArray = normalizeSearchParamsSchema(schema);

  schemaArray.forEach((paramSchema) => {
    const { name } = paramSchema;
    const value = searchParams.get(name as string);

    if (value === null) {
      return;
    }

    const parsedValue = castValue(value, paramSchema);

    if (parsedValue !== undefined) {
      parsedParams[name as string] = parsedValue;
    }
  });

  return parsedParams;
};
