import { Styles } from 'types/theme.type';

export const styles: Styles = {
  dialog: { '& .MuiDialog-paper': { maxWidth: '882px', height: 'clamp(550px, 650px, 60vh)' } },
  contentRoot: { height: '100%', justifyContent: 'space-between', gap: 1, pb: 2 },
  avatar: { width: 40, height: 40 },
  wrapper: { gap: 4 },
  box: { display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: 1, overflowY: 'auto' },
  row: { display: 'flex', alignItems: 'center', gap: 1 },
  swapIconBox: { height: 350, display: 'flex', alignItems: 'center', justifyContent: 'center' },
  buttonCancel: { color: 'text.primary' },
  buttonGroup: { display: 'flex', justifyContent: 'space-between', gap: 1, width: '100%' },
};
