import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { Middleware } from '@reduxjs/toolkit';

import { resetAll } from 'api';
import { ApiErrorCodes } from 'enums/apiErrorCodes';
import { ErrorData } from 'types/api.type';
import { redirectToLoginPage } from 'utils/auth';

const NOT_AUTHORIZED = 401;

export const redirectsHandler: Middleware = () => (next) => async (action) => {
  if (
    isRejectedWithValue(action) ||
    (action.payload?.status === 'PARSING_ERROR' &&
      action.payload?.originalStatus === NOT_AUTHORIZED)
  ) {
    const { status, data, originalStatus } = action.payload;

    const notAuthorized = status === NOT_AUTHORIZED || originalStatus === NOT_AUTHORIZED;
    const mfaRequired = (data as ErrorData)?.code === ApiErrorCodes.MFA_REQUIRED;
    const forbidden = status === 403;

    const needLogin = notAuthorized || (forbidden && mfaRequired);

    if (needLogin) {
      next(resetAll());
      redirectToLoginPage();
    }
  }

  return next(action);
};
