import { api } from 'api';
import { toFormData } from 'api/utils';
import { API_BASE_URL } from 'constants/url';
import {
  CurrentUser,
  AccountNotification,
  GetAccountNotificationsParams,
  UpdateAccountNotificationProps,
  AllowedRegions,
} from 'types/account.type';
import { ErrorData, PaginatedResponse } from 'types/api.type';
import { SendFeedbackRequestType } from 'types/feedback.type';
import { providesListTag } from 'utils';

const accountUrl = `${API_BASE_URL}/account`;

export const accountApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCurrentUser: build.query<CurrentUser, void>({
      query: () => `${accountUrl}/current_user`,
      providesTags: ['currentUser'],
      transformErrorResponse: (baseQueryReturnValue) => {
        if (
          baseQueryReturnValue.status === 'PARSING_ERROR' &&
          baseQueryReturnValue.originalStatus === 401
        ) {
          return {
            status: 401,
            data: { message: '401 Authorization Required' },
            originalData: baseQueryReturnValue,
          };
        }
        return baseQueryReturnValue;
      },
    }),

    getFlags: build.query<string[], void>({
      query: () => `${accountUrl}/flags`,
      providesTags: ['flags'],
      transformResponse({ flags }) {
        return flags;
      },
    }),

    changePassword: build.mutation<void, { oldPassword: string; newPassword: string }>({
      query: ({ oldPassword, newPassword }) => ({
        url: `${accountUrl}/password`,
        method: 'PUT',
        body: {
          old_password: oldPassword,
          new_password: newPassword,
        },
      }),
    }),

    sendFeedback: build.mutation<void, SendFeedbackRequestType>({
      query: (payload) => {
        const formData = toFormData({ ...payload, module: 'osint-band' });

        return {
          url: `${accountUrl}/feedback`,
          method: 'POST',
          body: formData,
          headers: {
            'Content-Type': undefined,
          },
        };
      },
    }),

    getAccountNotifications: build.query<
      PaginatedResponse<AccountNotification>,
      GetAccountNotificationsParams
    >({
      query: (payload) => ({
        url: `${accountUrl}/notifications`,
        params: payload,
      }),
      providesTags: (response) => providesListTag(response?.results, 'userNotifications'),
      transformErrorResponse: (error) => error.data as ErrorData,
    }),

    updateAccountNotification: build.mutation<void, UpdateAccountNotificationProps>({
      query: ({ notificationId, ...payload }) => ({
        url: `${accountUrl}/notifications/${notificationId}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (_res, error, arg) =>
        error ? [] : [{ type: 'userNotifications', id: arg.notificationId }, 'userNotifications'],
    }),

    getAllowedRegions: build.query<AllowedRegions, void>({
      query: () => ({
        url: `${accountUrl}/allowed_regions`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetCurrentUserQuery,
  useLazyGetCurrentUserQuery,
  useGetFlagsQuery,
  useChangePasswordMutation,
  useSendFeedbackMutation,
  useGetAccountNotificationsQuery,
  useLazyGetAccountNotificationsQuery,
  useUpdateAccountNotificationMutation,
  useGetAllowedRegionsQuery,
  useLazyGetAllowedRegionsQuery,
} = accountApi;
