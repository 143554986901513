import { ComponentProps, FC } from 'react';

import dayjs from 'dayjs';
import { Controller } from 'react-hook-form';

import { FormControl, FormHelperText } from '@mui/material';

import { DatePicker } from '../DatePicker';
import { FormErrorMessage } from '../FormErrorMessage';

type Props = ComponentProps<typeof DatePicker> & {
  name: string;
  format?: string;
  future?: boolean;
  size?: 'small' | 'medium';
  showErrors?: boolean;
  multipleErrors?: boolean;
};

export const FormDatePickerField: FC<Props> = ({
  name,
  label,
  slotProps,
  multipleErrors,
  future = true,
  size = 'small',
  showErrors = true,
  onChange,
  ...rest
}) => {
  return (
    <Controller
      name={name}
      render={({
        field: { onChange: cOnChange, value: cValue, ref: cRef },
        fieldState: { invalid, error },
      }) => {
        const date = cValue && dayjs(cValue);
        const validationMessage = showErrors ? (
          <FormErrorMessage error={error} multiple={multipleErrors} />
        ) : null;
        return (
          <FormControl component="fieldset" error={invalid}>
            <DatePicker
              {...rest}
              value={date || null}
              disableFuture={!future}
              onChange={(event, context) => {
                onChange(event, context);
                cOnChange(event);
              }}
              label={label}
              slotProps={{
                ...slotProps,
                textField: {
                  size,
                  error: invalid,
                  ...slotProps?.textField,
                },
                actionBar: { actions: ['clear'], ...slotProps?.actionBar },
              }}
              ref={cRef}
            />
            {invalid && <FormHelperText error>{validationMessage}</FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
};
