import { AuthenticationTemplate } from './components/AuthenticationTemplate';
import { Breadcrumbs } from './components/Breadcrumbs';
import { ContentGuard } from './components/ContentGuard';
import { CopyToClipboardButton } from './components/CopyToClipboardButton';
import { DateTimePicker } from './components/DateTimePicker';
import { FeedbackModal } from './components/FeedbackModal';
import { FilesSelect } from './components/FilesSelect';
import { FilterDrawerControl } from './components/FilterDrawerControl';
import { FiltersSidebar } from './components/FiltersSidebar';
import { FormAutocompleteField } from './components/FormAutocompleteField';
import { FormCheckbox } from './components/FormCheckbox';
import { FormDatePickerField } from './components/FormDatePickerField';
import { FormDateTimePickerField } from './components/FormDateTimePickerField';
import { FormFilesSelect } from './components/FormFilesSelect';
import { FormSelect } from './components/FormSelect';
import { FormSwitch } from './components/FormSwitch';
import { FormTextField } from './components/FormTextField';
import { FullScreenLoader } from './components/FullScreenLoader';
import { Loader } from './components/Loader';
import { LoadingIconButton } from './components/LoadingIconButton';
import { Notification } from './components/Notification';
import { PageContainer } from './components/PageContainer';
import { useLazyQuery } from './hooks/useLazyQuery';
import { useNavigationFilter } from './hooks/useNavigationFilter';
import { useQuery } from './hooks/useQuery';
import { AuthProvider } from './providers/AuthProvider';
import {
  ListFilterContextProvider,
  useListFilterContext,
} from './providers/ListFilterContextProvider';
import {
  usePlausibleAnalytics,
  PlausibleAnalyticsProvider,
} from './providers/PlausibleAnalyticsProvider';
import { ThemeProvider, ThemeMode } from './providers/ThemeProvider';
import { ERROR, INFO, PRIMARY, SUCCESS, WARNING } from './providers/ThemeProvider/palette';
import { type SearchQueryParamSchema } from './types/SearchQueryParamSchema';
import { type SearchQueryParamType } from './types/SearchQueryParamType';

const themePalette = { ERROR, INFO, PRIMARY, SUCCESS, WARNING };

export {
  ThemeProvider,
  ThemeMode,
  AuthProvider,
  PlausibleAnalyticsProvider,
  usePlausibleAnalytics,
  ListFilterContextProvider,
  useListFilterContext,
  FullScreenLoader,
  AuthenticationTemplate,
  ContentGuard,
  CopyToClipboardButton,
  DateTimePicker,
  FeedbackModal,
  Breadcrumbs,
  Loader,
  LoadingIconButton,
  Notification,
  PageContainer,
  FilesSelect,
  FilterDrawerControl,
  FiltersSidebar,
  FormAutocompleteField,
  FormCheckbox,
  FormDatePickerField,
  FormDateTimePickerField,
  FormFilesSelect,
  FormSelect,
  FormSwitch,
  FormTextField,
  useLazyQuery,
  useQuery,
  useNavigationFilter,
  themePalette,
};

export type { SearchQueryParamType, SearchQueryParamSchema };
