export const GREY = {
  0: '#FFFFFF',
  50: '#FAFAFA',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#ACACAC',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

export const PRIMARY = {
  lighter: '#90caf9',
  light: '#4fc3f7',
  main: '#1976d2',
  dark: '#42a5f5',
  darker: '#42a5f5',
  contrastText: '#fff',
};

export const SECONDARY = {
  lighter: '#f5f5f5',
  light: '#f3e5f5',
  main: '#9C27B0',
  dark: '#7B1FA2',
  darker: '#ab47bc',
  contrastText: '#fff',
};

export const INFO = {
  lighter: '#e1f5fe',
  light: '#4fc3f7',
  main: '#2196F3',
  dark: '#0288d1',
  darker: '#0288d1',
  contrastText: '#fff',
};

export const SUCCESS = {
  lighter: '#daecdc',
  light: '#c7efc9',
  main: '#2e7d32',
  dark: '#388e3c',
  darker: '#388e3c',
  contrastText: '#fff',
};

export const WARNING = {
  lighter: '#fff7ce',
  light: '#ffd600',
  main: '#ffa726',
  dark: '#f57c00',
  darker: '#ed6c02',
  contrastText: GREY[800],
};

export const ERROR = {
  lighter: '#ffe8e8',
  light: '#f2c7c7',
  main: '#f44336',
  dark: '#d32f2f',
  darker: '#ff0000',
  contrastText: '#fff',
};
