import { FC } from 'react';

import { Avatar, Box, Stack, Skeleton, Typography, Tooltip } from '@mui/material';

import { AvatarCard } from 'components/AvatarCard';

import { styles } from '../../styles';
import { UserInfoProps } from '../../types';

export const SKELETON_BOX_TEST_ID = 'skeleton';
const MOCK_CHIP_COUNT_ITEMS = 8;

export const UserInfo: FC<UserInfoProps> = ({ userInfo, isUserLoading }) => {
  const { fullName, avatar } = userInfo;

  return (
    <>
      {isUserLoading && (
        <Stack sx={styles.wrapper} data-testid={SKELETON_BOX_TEST_ID}>
          <Box sx={styles.row}>
            <Skeleton animation="wave" variant="circular" sx={styles.avatar} />
            <Skeleton animation="wave" variant="text" width={260} role="progressbar" />
          </Box>

          <Box sx={styles.box}>
            {Array.from({ length: MOCK_CHIP_COUNT_ITEMS }).map((_, index) => (
              <Skeleton key={index} animation="wave" variant="rounded" width={100} />
            ))}
          </Box>
        </Stack>
      )}

      {!isUserLoading && (
        <Stack sx={styles.wrapper}>
          <Box sx={styles.row}>
            {avatar && (
              <Avatar sx={styles.avatar} alt={avatar.photo}>
                <AvatarCard avatarInfo={avatar} hideToolbar />
              </Avatar>
            )}
            <Tooltip title={fullName} placement="top">
              <Typography variant="h6" fontSize={16} noWrap>
                {fullName}
              </Typography>
            </Tooltip>
          </Box>
        </Stack>
      )}
    </>
  );
};
