import { FC } from 'react';

import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Card, CardMedia, CircularProgress, SxProps } from '@mui/material';

import { useS3ProxyUrl } from 'hooks/useS3ProxyUrl';

import { CardWrapperStyle, IconPlayStyle } from './styles';

type Props = {
  photo: string;
  isVideo?: boolean;
  sx?: SxProps;
  onClick?: () => void;
};

export const AvatarMedia: FC<Props> = ({ isVideo = false, photo, sx, onClick }) => {
  const isVideoAvatar = isVideo && photo;
  const [photoPreparedLink, { isLoading }] = useS3ProxyUrl(photo);

  return (
    <Card sx={[CardWrapperStyle, sx] as SxProps} onClick={onClick}>
      {isLoading && <CircularProgress size={40} thickness={2} />}
      {!isLoading && isVideoAvatar && (
        <>
          <PlayCircleOutlineIcon sx={IconPlayStyle} />
          <CardMedia
            component="video"
            itemType="mp4"
            src={photoPreparedLink}
            autoPlay={false}
            muted
            loop
          />
        </>
      )}
      {!isLoading && !isVideoAvatar && (
        <CardMedia component="img" image={photoPreparedLink} loading="lazy" />
      )}
    </Card>
  );
};
