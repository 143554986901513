import { alpha, GlobalStyles as MUIGlobalStyles } from '@mui/material';

import { GREY } from './palette';

const GlobalStyles = (): React.ReactElement => {
  const inputGlobalStyles = (
    <MUIGlobalStyles
      styles={(theme) => ({
        html: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
        },
        body: {
          margin: 0,
          width: '100%',
          height: '100%',
        },
        'ul, li': {
          margin: 0,
          padding: 0,
          listStyle: 'none',
        },
        a: { textDecoration: 'none' },
        ':root': {
          '--mui-palette-border-warning-light': alpha(theme.palette.warning.dark, 0.2),
        },
        // react-color library
        '.github-picker-marker': {
          gap: '4px',
          '& span': {
            border: `1px solid ${GREY[500]}`,
          },
        },
        // react-easy-sorting global classes
        '.dragged-list': {
          backgroundColor: theme.palette.grey[100],
        },
        '.dragged-item__active': {
          cursor: 'grabbing',
          boxShadow:
            '0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.20)',
        },
        '.text-transform-none': {
          textTransform: 'none',
          '&.MuiButton-root': {
            textTransform: 'none',
          },
        },
      })}
    />
  );

  return inputGlobalStyles;
};

export default GlobalStyles;
