import { GlobalStyles as MUIGlobalStyles } from '@mui/material';

export const GlobalStyles = () => {
  return (
    <MUIGlobalStyles
      styles={(theme) => ({
        html: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
        },
        body: {
          margin: 0,
          width: '100%',
          height: '100%',
          backgroundColor: `${theme.palette.mode === 'dark' ? '#303030' : '#EEEEEE'} !important`,
        },
        ':root': {
          '--mui-gap': '8px',
        },
        '.MuiInputBase-input': {
          '&:-webkit-autofill': {
            WebkitBoxShadow: 'none',
            WebkitTextFillColor: 'initial',
            caretColor: 'initial',
          },
        },
      })}
    />
  );
};
