import { type SxProps } from '@mui/material';

export const styles: Record<string, SxProps> = {
  title: {
    color: 'text.primary',
  },
  filtersFormWrapper: {
    position: 'relative',
    width: '100%',
    maxWidth: 340,
    px: 2,
    py: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    height: '100%',
  },
  sidebarHeading: {
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid',
    borderBottomColor: 'divider',
    pb: 0.5,
  },
  filterActionsBox: {
    width: 1,
    display: 'flex',
    gap: 2,
    flexDirection: 'row',
    button: {
      flexBasis: '50%',
      flexGrow: 1,
    },
  },
  formControlsBox: {
    flexGrow: 1,
    overflow: 'auto',
    pt: 0.5,
    '&::-webkit-scrollbar': {
      width: 0,
      background: 'transparent',
    },
  },
  closeButton: {
    position: 'absolute',
    top: 7,
    right: -35,
  },
};
