import { oidcUserManagerInstance } from '../oidc/oidcUserManagerInstance';

const getRedirectUri = () => {
  const { pathname, search } = window.location;
  const redirectUri = !pathname.toLowerCase().startsWith('/auth')
    ? `?redirectUrl=${encodeURI(pathname + search)}`
    : undefined;
  return redirectUri;
};

export function httpResponseUnauthorizedInterceptor(error) {
  if (error?.response?.status === 401) {
    oidcUserManagerInstance.signinRedirect({ redirect_uri: getRedirectUri() });
  }
  return Promise.reject(error);
}
