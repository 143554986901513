import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './Notification.scss';

const Notification = () => (
  <ToastContainer closeButton pauseOnHover closeOnClick autoClose={5000} hideProgressBar={false} />
);

export default Notification;
