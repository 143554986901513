(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("oidc-client-ts"));
	else if(typeof define === 'function' && define.amd)
		define(["oidc-client-ts"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("oidc-client-ts")) : factory(root["oidc-client-ts"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(this, (__WEBPACK_EXTERNAL_MODULE__383__) => {
return 