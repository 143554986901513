import { ComponentProps, FC, ReactElement } from 'react';

import { Controller } from 'react-hook-form';

import { Checkbox, FormControl, FormControlLabel, FormHelperText, Switch } from '@mui/material';

import { FormErrorMessage } from '../FormErrorMessage';

type Props = Omit<ComponentProps<typeof Checkbox>, 'name' | 'size'> & {
  name: string;
  label: string | ReactElement;
  showErrors?: boolean;
  multipleErrors?: boolean;
  size?: 'small' | 'medium';
  type?: 'switch' | 'checkbox';
};

export const FormCheckbox: FC<Props> = ({
  name,
  label,
  multipleErrors,
  onChange,
  showErrors = true,
  type = 'checkbox',
  size,
  ...props
}) => {
  const CheckboxComponent = type === 'switch' ? Switch : Checkbox;
  return (
    <Controller
      name={name}
      render={({
        field: { onChange: cOnChange, onBlur: cOnBlur, value: cValue, ref: cRef },
        fieldState: { invalid, error },
      }) => {
        const validationMessage = showErrors ? (
          <FormErrorMessage error={error} multiple={multipleErrors} />
        ) : null;
        return (
          <FormControl error={invalid}>
            <FormControlLabel
              sx={{ margin: 0 }}
              control={
                <CheckboxComponent
                  {...props}
                  checked={cValue}
                  inputProps={{ ...props.inputProps, type: 'checkbox' }}
                  onChange={(event, checked) => {
                    onChange?.(event, checked);
                    cOnChange(event);
                  }}
                  onBlur={cOnBlur}
                  inputRef={cRef}
                  size={size}
                />
              }
              label={label}
            />
            {validationMessage && <FormHelperText>{validationMessage}</FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
};
