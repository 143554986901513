import { api } from 'api';
import { DEFAULT_PRIORITY } from 'constants/tags';
import { API_BASE_URL } from 'constants/url';
import { CountryRegionTag, BandTag } from 'types/tags.type';

const userTagsUrl = `${API_BASE_URL}/user_tags`;
const countryRegionTagsUrl = `${API_BASE_URL}/country_region_tags`;

const tagsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUserTags: build.query<BandTag[], { addableOnly: boolean } | void>({
      query: ({ addableOnly } = { addableOnly: false }) =>
        `${userTagsUrl}${addableOnly ? '?addable=true' : ''}`,
      transformResponse: (res: BandTag[]) => res.sort((a, b) => a.priority - b.priority),
    }),

    getCountryRegionTags: build.query<CountryRegionTag[], void>({
      query: () => countryRegionTagsUrl,
      transformResponse: (res: CountryRegionTag[]) =>
        // TODO: delete fallbacks to DEFAULT_PRIORITY once OB-2143 is resolved
        res.sort((a, b) => (a.priority ?? DEFAULT_PRIORITY) - (b.priority ?? DEFAULT_PRIORITY)),
    }),
  }),
});

export const { useGetUserTagsQuery, useGetCountryRegionTagsQuery, useLazyGetUserTagsQuery } =
  tagsApi;
