export enum DossierSocialConnectorId {
  UNKNOWN = 0,
  TELEGRAM = 1,
  INSTAGRAM = 2,
  TIKTOK = 3,
  VKONTAKTE = 4,
  TINDER = 5,
  BADOO = 6,
  ODNOKLASSNIKI = 7,
  FACEBOOK = 8,
  WHATSAPP = 9,
  SKYPE = 10,
  MAMBA = 11,
  TWITTER = 13,
  MOYMIR = 14,
  LOVEPLANET = 15,
}

export enum DossierKeyPropsName {
  ARMY_RANK = 'armyRank',
  ARMY_RANKS = 'armyRanks',
  AVATAR_INFO = 'avatarInfo',
  MILITARY_EXPERIENCES = 'militaryExperiences',
  MILITARY_FUNCTION = 'militaryFunction',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  MIDDLE_NAME = 'middleName',
  IDENTITY_NUMBER = 'identityNumber',
  RUS_ARMY_TOKEN = 'rusArmyToken',
  DOMICILE_REGISTRATION = 'domicileRegistration',
  PERSONAL_ID = 'personalId',
  EDUCATIONS = 'educations',
  FAMILY_STATUS = 'familyStatus',
  FAMILY_STATUSES = 'familyStatuses',
  CALLSIGN = 'callsign',
  CALLSIGNS = 'callsigns',
  BIRTHDAY = 'birthday',
  NATIONALITY = 'nationality',
  NATIONALITIES = 'nationalities',
  BIRTH_PLACE = 'birthplace',
  DESCRIPTION = 'description',
  CARS = 'cars',
  IDENTITY_DOCUMENTS = 'identityDocuments',
  NEW_PASSPORTS = 'rusNewFormatPassports',
  OLD_PASSPORTS = 'rusOldFormatPassports',
  FOREIGN_PASSPORTS = 'rusForeignPassports',
  DRIVER_LICENSES = 'rusDriverLicenses',
  MILITARY_CARDS = 'rusMilitaryCards',
  PHONES = 'phones',
  EMAILS = 'emails',
  ADDRESSES = 'addresses',
  LINKS = 'links',
  CONNECTORS = 'connectors',
  RELATIVES = 'relatives',
  RUS_SNILS = 'rusSnils',
  MYROTOVORETS_LINK = 'myrotvoretsLink',
  AD_MID = 'adMid',
  FLAGS = 'flagIds',
}

export enum DossierFileType {
  UPLOADED_DOC_FILE = 40,
  UPLOADED_PHOTO = 41,
}

export enum SourceId {
  UNKNOWN = 0,
  TELEGRAM = 1,
  INSTAGRAM = 2,
  TIK_TOK = 3,
  VKONTAKTE = 4,
  TINDER = 5,
  BADOO = 6,
  ODNOKLASSNIKI = 7,
  FACEBOOK = 8,
  WHATSAPP = 9,
  SKYPE = 10,
  MAMBA = 11,
  DOSSIER = 100,
}

export enum DossierFlags {
  ENABLE_DELTA_MONITORING = 100,
  DELTA_VIP = 101,
  CONNECTOR_READ_ONLY = 151,
  CONNECTOR_FROM_TELEGRAM = 152,
}

export enum MilitaryFieldsName {
  ARMY_RANK = 'armyRank',
  MILITARY_POST = 'militaryPost',
  MILITARY_UNIT = 'militaryUnit',
  MILITARY_EXISTED_UNIT = 'militaryExistedUnit',
  MILITARY_FUNCTION = 'militaryFunction',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  COMMENT = 'comment',
}
