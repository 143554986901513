export enum UserProfileType {
  SUPERUSER = 0,
  SUPERANALYTIC = 1,
  REGULAR = 2,
  LOGIN_ONLY = 3,
}

export enum NotificationsType {
  INFO = 0,
  WARNING = 1,
  SUCCESS = 2,
  ERROR = 3,
}
