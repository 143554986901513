import { FC, ReactNode } from 'react';

import { Link as RouterLink } from 'react-router-dom';

import { Link, type SxProps, Typography } from '@mui/material';

const styles: Record<string, SxProps> = {
  breadcrumbLink: {
    color: 'inherit',
    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'underline',
    },
  },

  scanInitBreadcrumb: {
    verticalAlign: 'super',
  },
};

type Props = {
  isLast: boolean;
  breadcrumb: ReactNode;
  to: string;
};

export const BreadcrumbItem: FC<Props> = ({ isLast, breadcrumb, to }) => {
  return (
    <>
      {isLast && (
        <Typography component="span" color="text.primary">
          {breadcrumb}
        </Typography>
      )}
      {!isLast && (
        <Link sx={styles.breadcrumbLink} component={RouterLink} to={to}>
          {breadcrumb}
        </Link>
      )}
    </>
  );
};
