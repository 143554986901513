import { FunctionComponent } from 'react';

import ConfirmDialog from 'modals/ConfirmDialog';
import ManageUserTags from 'modals/ManageUserTags';
import PromptDialog from 'modals/PromptDialog';

import { ModalNames } from './enums';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ModalByName: Record<ModalNames, FunctionComponent<any>> = {
  [ModalNames.createUserTag]: ManageUserTags,

  [ModalNames.confirmDialog]: ConfirmDialog,
  [ModalNames.promptDialog]: PromptDialog,
};
