import { SxProps, Theme } from '@mui/material';

import { Styles } from 'types/theme.type';

const flexFieldBox: SxProps<Theme> = { display: 'flex', flexDirection: 'column', gap: 2 };
export const styles: Styles = {
  dialog: (theme) => ({
    '& .MuiPaper-root': { width: '500px' },
    '& .MuiDialogTitle-root': { textAlign: 'center', pl: 6.5 },
    '& .MuiDialogContent-root': { p: 3, pt: '6px' },
    '& .MuiButton-root': { maxWidth: 400, alignSelf: 'center' },
    [theme.breakpoints.down('sm')]: {
      '& .MuiPaper-root': { margin: 2 },
      '& .MuiDialogContent-root': { px: 2 },
    },

    '& .prompt-dialog-form-wrapper': {
      ...flexFieldBox,
      margin: '0 auto',
      pt: '6px',
      '& .prompt-dialog-form': {
        ...flexFieldBox,
        minWidth: '100%',
      },
    },
  }),
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    mt: 1,
    gap: 1,
  },
};
