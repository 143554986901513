import { ROUTES } from './routes';

export const USE_AUTH_RESTORE_CODES = false;

const publicUrl = process.env.PUBLIC_URL;
export const LOGIN_PAGE_URL = `${publicUrl}${
  publicUrl.toLowerCase().includes('/login') ? '' : ROUTES.auth.login
}`;

export const ERR_MSG =
  'Виникли труднощі з входом у систему. Спробуйте ще раз, а в разі повторної відмови зверніться в Підтримку.';
