import { FC } from 'react';

import { Box } from '@mui/material';

import BandTagChip from 'components/BandTagChip';
import { BandTag } from 'types/tags.type';

import { styles } from '../../styles';
import { UseManageUserTagsReturnType } from '../../types';

type TagListProps = {
  tags: UseManageUserTagsReturnType['matchedTags'];
  onClick: UseManageUserTagsReturnType['handleTagClick'];
  canDelete?: (tag: BandTag) => boolean;
};

export const TagList: FC<TagListProps> = ({ tags, onClick, canDelete }) => (
  <Box sx={styles.box} role="listbox">
    {tags.map((tag) => (
      <BandTagChip
        key={tag.id}
        size="small"
        name={tag.name}
        priority={tag.priority}
        disabled={!tag.removable}
        onClick={() => onClick(tag.id)}
        onDelete={canDelete?.(tag) ? () => onClick(tag.id) : undefined}
      />
    ))}
  </Box>
);
