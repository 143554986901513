import { ComponentProps, FC } from 'react';

import { Dayjs } from 'dayjs';

import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';

export const DATE_FORMAT = 'DD.MM.YYYY';

export const DatePicker: FC<ComponentProps<typeof MuiDatePicker<Dayjs>>> = ({
  views = ['year', 'month', 'day'] as const,
  format = DATE_FORMAT,
  ...restProps
}) => {
  return <MuiDatePicker views={views} format={format} {...restProps} />;
};
