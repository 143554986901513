import { FC, useEffect, useState, memo } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {
  Box,
  IconButton,
  CardMedia,
  Typography,
  ImageListItemBar,
  type SxProps,
  type Theme,
} from '@mui/material';

import { fileToDataUrl, getSizeLabel } from './utils';

const styles: Record<string, SxProps<Theme>> = {
  filePreviewWrapper: {
    position: 'relative',
    borderRadius: 2,
    overflow: 'hidden',
  },
  deleteButton: (theme) => ({
    position: 'absolute',
    right: 0,
    top: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    color: theme.palette.grey[50],
    boxShadow: theme.shadows[2],
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      boxShadow: theme.shadows[3],
    },
    '&:disabled': {
      boxShadow: theme.shadows[1],
    },
  }),
  filePreviewIcon: {
    width: 75,
    height: 75,
    margin: '0 auto',
    display: 'block',
    color: 'text.disabled',
  },
  filePreviewType: {
    width: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    backgroundColor: 'error.main',
    position: 'absolute',
    top: '30%',
    px: 1.5,
  },
};

type Props = {
  file: File;
  width?: number;
  height?: number;
  disabled?: boolean;
  className?: string;
  errorText?: string;
  onDelete?: (file: File) => void;
};

const FilesPreview: FC<Props> = memo(
  ({
    file,
    className,
    errorText,
    width = 98,
    height = 98,
    disabled = false,
    onDelete = () => {},
  }) => {
    const [imageSrc, setImageSrc] = useState<string>('');
    useEffect(() => {
      if (file) {
        fileToDataUrl(file).then(setImageSrc);
      }
    }, [file]);
    const fileNameLowerCase = file.name.toLowerCase();
    const isFileImage =
      file.type.startsWith('image') ||
      fileNameLowerCase.endsWith('png') ||
      fileNameLowerCase.endsWith('jpg') ||
      fileNameLowerCase.endsWith('jpeg');
    return (
      <Box
        sx={styles.filePreviewWrapper}
        width={width}
        height={height}
        title={errorText ? `${file.name}: ${errorText}` : file.name}
        className={className}
      >
        <IconButton
          sx={styles.deleteButton}
          size="small"
          onClick={() => onDelete(file)}
          disabled={disabled}
        >
          <CloseIcon fontSize="small" color="error" />
        </IconButton>
        {isFileImage && (
          <>
            <CardMedia
              component="img"
              width="100%"
              height="100%"
              image={imageSrc}
              alt={file.name}
            />
            <ImageListItemBar position="bottom" subtitle={getSizeLabel(file.size)} />
          </>
        )}
        {!isFileImage && (
          <Box width={width} height={height}>
            <InsertDriveFileIcon sx={styles.filePreviewIcon} />
            <Box sx={styles.filePreviewType}>{file.name.split('.').pop()}</Box>
            <Typography variant="body2" color="text.disabled" noWrap>
              {file.name}
            </Typography>
          </Box>
        )}
      </Box>
    );
  },
);

export default FilesPreview;
