import { createAction } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL } from 'constants/url';

import { getConfiguredBaseQuery } from './getConfiguredBaseQuery';

export const resetAll = createAction('reset/all');

export const api = createApi({
  /**
   * `reducerPath` is optional and will not be required by most users.
   * This is useful if you have multiple API definitions,
   * e.g. where each has a different domain, with no interaction between endpoints.
   * Otherwise, a single API definition should be used in order to support tag invalidation,
   * among other features
   */
  reducerPath: 'splitApi',
  /**
   * A bare bones base query would just be `baseQuery: fetchBaseQuery({ baseUrl: '/' })`
   */
  baseQuery: getConfiguredBaseQuery(API_BASE_URL),
  /**
   * Tag types must be defined in the original API definition
   * for any tags that would be provided by injected endpoints
   */
  tagTypes: [
    'alpineQuests',
    'authMfaStatus',
    'currentUser',
    'bandByConnector',
    'bandFullProfile',
    'dossier',
    'dossierPhotos',
    'dossierConnectors',
    'dossierPermissions',
    'dossierRelatedTags',
    'dossierPhotoRelatedTags',
    'dossierNais',
    'dossierSearch',
    'dossierChanges',
    'dossiers',
    'flags',
    'intersections',
    'locationDossiers',
    'mambaScans',
    'mambaProfiles',
    'scheduledSearchArchives',
    'scheduledSearches',
    'searchArchives',
    'searchByNumbersDossiers',
    'searchClusters',
    'guardianClusters',
    'userPositions',
    'searchCapacity',
    'searchUsers',
    'searches',
    'searchesByNumbers',
    'sessions',
    'telezipSearches',
    'user',
    'userNotifications',
    'userProfile',
    'userTags',
    'userVkIds',
    'profilesGroupsList',
    'profilesGroupsByProfile',
    'profilesGroupPersonsList',
    'profilesGroupStatusesList',
    'vkProfileFromDB',
    'vkSearchResults',
    'vkResolveScreenName',
    'vkGroupSubscribers',
    'vkSearchByNumbers',
    'vkSearchByNumbersResults',
    'vkSearchByNumbersVkIds',
    'facesSearches',
  ],
  /**
   * This api has endpoints injected in adjacent files,
   * which is why no endpoints are shown below.
   * If you want all endpoints defined in the same file, they could be included here instead
   */
  endpoints: () => ({}),
});
