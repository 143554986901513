import dayjs from 'dayjs';

export const filterEmptyParams = <T extends object>(data: T): Partial<T> => {
  const queryParams = Object.entries(data)
    .filter(([, value]) => {
      if (Array.isArray(value) && value.length > 0) {
        return value;
      }
      if (value instanceof dayjs) {
        return value;
      }
      return isNotEmptyValue(value);
    })
    .map(([key, value]) => {
      if (dayjs.isDayjs(value)) {
        return [key, dayjs(value).format()];
      }
      return [key, value];
    });
  return Object.fromEntries(queryParams);
};
