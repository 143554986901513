import { FC, PropsWithChildren, useEffect, useState } from 'react';

import { useAuth } from 'react-oidc-context';

import { FullScreenLoader } from '../FullScreenLoader';

let authState = null;

const getRedirectUri = () => {
  const { origin, pathname, search } = window.location;
  const redirectUri = !pathname.toLowerCase().startsWith('/auth')
    ? `?redirectUrl=${encodeURI(pathname + search)}`
    : '';
  return `${origin}/auth/oidc/postlogin${redirectUri}`;
};

type Props = {
  interactionType?: 'redirect' | 'popup';
  displayError?: boolean;
  querySessionInterval?: number;
};

export const AuthenticationTemplate: FC<PropsWithChildren<Props>> = ({
  children,
  interactionType = 'redirect',
  displayError = true,
  querySessionInterval = 20_000,
}) => {
  const [authId] = useState(Math.random());
  const auth = useAuth();

  useEffect(() => {
    let interval = undefined;
    const accessToken = auth?.user?.access_token;
    if (displayError && querySessionInterval && accessToken) {
      interval = setInterval(() => {
        fetch(`${process.env.REACT_APP_OIDC_AUTHORITY}/protocol/openid-connect/userinfo`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        }).then((response) => {
          if (response.status === 401) {
            auth.signinSilent();
          }
        });
      }, querySessionInterval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [displayError, auth]);

  if (!authState) {
    authState = {
      isLoading: !!auth?.isLoading,
      isAuthenticated: !!auth?.isAuthenticated,
      authId,
    };
  }
  if (authState.authId === authId) {
    authState.isLoading = !!auth?.isLoading;
  }

  useEffect(() => {
    if (
      authState.authId === authId &&
      !auth?.isAuthenticated &&
      !auth?.isLoading &&
      !auth?.activeNavigator &&
      !auth?.error
    ) {
      switch (interactionType) {
        case 'popup':
          auth.signinPopup();
        case 'redirect':
        default:
          auth.signinRedirect({ redirect_uri: getRedirectUri() });
      }
    }
  }, [auth, authId]);

  switch (auth?.activeNavigator) {
    case 'signinRedirect':
      return <div>Signing you in...</div>;
    case 'signoutRedirect':
      return <div>Signing you out...</div>;
  }

  if (auth?.isLoading && auth.activeNavigator !== 'signinSilent') {
    return <FullScreenLoader open delay={300} />;
  }

  if (auth?.error) {
    const errorMessage = auth.error.message;
    const redirectErrors = [
      'login_required',
      'Token is not active',
      'Session not active',
      'Failed to fetch',
    ];
    if (redirectErrors.includes(errorMessage)) {
      auth.signinRedirect({ redirect_uri: getRedirectUri() });
      return null;
    }
    return !!displayError && <div>Oops... {errorMessage}</div>;
  }

  if (!auth?.isAuthenticated) {
    return null;
  }

  return <>{children}</>;
};
