import { forwardRef } from 'react';

import { Controller } from 'react-hook-form';

import { TextField, type TextFieldProps } from '@mui/material';

import { FormErrorMessage } from '../FormErrorMessage';

type Props = {
  name: string;
  showErrors?: boolean;
  multipleErrors?: boolean;
} & Omit<TextFieldProps, 'name'>;

export const FormTextField = forwardRef<HTMLDivElement, Props>(
  ({ name, showErrors = true, multipleErrors = false, onChange, onBlur, ...rest }: Props) => {
    return (
      <Controller
        name={name}
        render={({
          field: { onChange: cOnChange, onBlur: cOnBlur, value: cValue, ref: cRef },
          fieldState: { invalid, error },
        }) => {
          const validationMessage = showErrors ? (
            <FormErrorMessage error={error} multiple={multipleErrors} />
          ) : null;
          return (
            <TextField
              {...rest}
              name={name}
              value={cValue}
              onChange={(event) => {
                onChange?.(event);
                cOnChange(event);
              }}
              onBlur={(event) => {
                onBlur?.(event);
                cOnBlur();
              }}
              inputRef={cRef}
              error={invalid}
              helperText={validationMessage}
            />
          );
        }}
      />
    );
  },
);
