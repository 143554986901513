import { FC } from 'react';

import { type FieldError } from 'react-hook-form';

import { Box } from '@mui/material';

const styles = {
  li: { listStyleType: 'disc' },
  ul: {
    padding: 0,
    margin: 0,
  },
};

type Props = {
  error: FieldError;
  multiple?: boolean;
  styledList?: boolean;
};

export const FormErrorMessage: FC<Props> = ({ error, multiple = false }) => {
  if (!error) {
    return null;
  }
  if (!multiple) {
    return <span>{error.message}</span>;
  }

  return error.types ? (
    <Box component="ul" sx={styles.ul}>
      {Object.entries(error.types).map(([type, message]) => (
        <Box component="li" sx={styles.li} key={type}>
          {message}
        </Box>
      ))}
    </Box>
  ) : (
    <span>{error.message}</span>
  );
};
