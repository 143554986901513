import { FC, PropsWithChildren, useEffect, useState } from 'react';

import { type User } from 'oidc-client-ts';
import { useAuth } from 'react-oidc-context';

type Props = {
  guard?: (user: User) => Promise<boolean> | null;
};

export const ContentGuard: FC<PropsWithChildren<Props>> = ({ guard, children }) => {
  const [show, setShow] = useState(!guard);
  const auth = useAuth();

  useEffect(() => {
    if (!auth?.user || !guard) return;

    guard(auth.user).then((access) => setShow(access));
  }, [guard, auth?.user?.profile]);

  if (!show) return null;

  return children;
};
