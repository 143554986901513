import { useEffect } from 'react';

import { type AxiosRequestConfig } from 'axios';

import { useLazyQuery } from './useLazyQuery';
import { type TransformResponseFunc } from '../types/TransformResponseFunc';

export type UseQueryOptions = { skip?: boolean };

export const useQuery = <R, P = undefined>(
  params: Omit<AxiosRequestConfig, 'transformResponse'> & TransformResponseFunc<R>,
  options?: UseQueryOptions,
) => {
  const [trigger, responseResult] = useLazyQuery<R, P>({ query: () => params });

  useEffect(() => {
    if (options?.skip) return;
    trigger();
  }, [
    options?.skip,
    params.url,
    JSON.stringify(params.data),
    JSON.stringify(params.params),
    JSON.stringify(params.headers),
  ]);

  return responseResult;
};
