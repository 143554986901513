import { useState } from 'react';

import { httpRequestAuthInterceptor } from '@ornament/common';

type GetPhotoS3UrlResponse = { url: string };

const cache = new Map<string, string>();

export const useLazyGetPhotoS3UrlQuery = (): [
  (props: { url: string }) => Promise<GetPhotoS3UrlResponse>,
  {
    isLoading: boolean;
    data: GetPhotoS3UrlResponse | undefined;
    error: Error | unknown | undefined;
  }
] => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<GetPhotoS3UrlResponse>();
  const [error, setError] = useState<Error | unknown>();

  const getPhotoS3Url = async ({ url }: { url: string }) => {
    const cachedUrl = cache.get(url);
    if (cachedUrl) {
      return { url: cachedUrl };
    }

    setIsLoading(true);
    const config = await httpRequestAuthInterceptor({});
    try {
      const data = await fetch(url, {
        headers: { Authorization: config.headers.Authorization },
        redirect: 'follow',
      });
      const result = { url: data.url };
      cache.set(url, data.url);
      setData(result);
      return result;
    } catch (error) {
      setError(error);
      return Promise.reject(error);
    } finally {
      setIsLoading(false);
    }
  };

  return [
    getPhotoS3Url,
    {
      isLoading,
      data,
      error,
    },
  ];
};
