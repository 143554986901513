// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PageContainer-module-root--WqiE5{padding:calc(var(--mui-spacing)*3);height:100%}@media screen and (max-width: 600px){.PageContainer-module-root--WqiE5{padding:var(--mui-spacing)}}@media screen and (max-width: 900px){.PageContainer-module-root--WqiE5{padding:calc(var(--mui-spacing)*2)}}`, "",{"version":3,"sources":["webpack://./src/components/PageContainer/PageContainer.module.scss"],"names":[],"mappings":"AAAA,kCACE,kCAAA,CACA,WAAA,CACA,qCAHF,kCAII,0BAAA,CAAA,CAEF,qCANF,kCAOI,kCAAA,CAAA","sourcesContent":[".root {\n  padding: calc(var(--mui-spacing) * 3);\n  height: 100%;\n  @media screen and (max-width: 600px) {\n    padding: var(--mui-spacing);\n  }\n  @media screen and (max-width: 900px) {\n    padding: calc(var(--mui-spacing) * 2);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `PageContainer-module-root--WqiE5`
};
export default ___CSS_LOADER_EXPORT___;
