import { SearchQueryParam } from '../types/SearchQueryParam';
import { SearchQueryParamSchema } from '../types/SearchQueryParamSchema';

export const normalizeSearchParamsSchema = <T extends object>(
  scheme: SearchQueryParam<T>[] | SearchQueryParamSchema<T>,
) => {
  return !Array.isArray(scheme)
    ? (Object.entries(scheme).map(([name, type]) => ({ name, type })) as SearchQueryParam<T>[])
    : scheme;
};
