import { type ComponentProps, type FC } from 'react';

import { Controller } from 'react-hook-form';

import { FilesSelect } from '../FilesSelect';

type Props = { name: string } & ComponentProps<typeof FilesSelect>;

const FormFilesSelect: FC<Props> = ({ name, ...props }) => {
  return (
    <Controller
      name={name}
      render={({ field, fieldState }) => {
        let errorText = '';
        let errors = null;
        if (fieldState.error) {
          if (typeof fieldState.error === 'string') {
            errorText = fieldState.error;
          } else if (Array.isArray(fieldState.error)) {
            if (typeof fieldState.error[0] === 'string') {
              errorText = fieldState.error[0];
            } else {
              errorText = (fieldState.error[0] as { text: string })?.text;
              errors = fieldState.error;
            }
          }
        }
        return (
          <FilesSelect
            files={field.value}
            {...props}
            errorText={errorText}
            onChange={(value) => field.onChange(value)}
            errors={errors}
          />
        );
      }}
    />
  );
};

export { FormFilesSelect };
