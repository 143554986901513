import {
  ChangeEvent,
  FC,
  DragEventHandler,
  useState,
  useEffect,
  useCallback,
  ReactNode,
  useId,
  MouseEventHandler,
  useRef,
} from 'react';

import { useDebounceValue } from 'usehooks-ts';

import { Box, Button, FormHelperText, type SxProps, type Theme, Typography } from '@mui/material';

import FilePreviewList from './FilePreviewList';
import { styles } from './styles';
import { MimeType } from './types';
import { filterAccept } from './utils';

type Props = Partial<{
  files: File[] | null;
  maxLength: number;
  maxSize: number;
  accept: MimeType[] | string[];
  multiple: boolean;
  placeholder: ReactNode;
  helperText: string;
  errorText: string;
  previewMode: 'list' | 'preview';
  previewPosition: 'inbox' | 'bottom';
  customActions: ReactNode;
  disabled: boolean;
  disabledActions: boolean;
  selectFileButtonText: string;
  sx: SxProps<Theme>;
  allowDuplicates: boolean;
  errors: { name: string; text: string }[] | null;
  onChange: (files: File[] | null) => void;
}> &
  (
    | {
        allowPaste?: true;
        hideActions?: false;
      }
    | {
        allowPaste: false;
        hideActions?: boolean;
      }
  );

const FilesSelect: FC<Props> = ({
  maxLength,
  maxSize,
  customActions,
  sx,
  files = [],
  hideActions = false,
  accept = [],
  allowPaste = true,
  multiple = true,
  placeholder = '',
  helperText = '',
  errorText = '',
  previewMode = 'list',
  previewPosition = 'inbox',
  disabled = false,
  disabledActions = false,
  selectFileButtonText = 'Завантажити файл',
  allowDuplicates = false,
  errors = null,

  onChange = () => {},
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [isDraggingDebounced] = useDebounceValue(isDragging, 100);
  const inputRef = useRef<HTMLInputElement>(null);

  const updateFiles = (fileList: FileList | File[] | null) => {
    if (fileList) {
      let newFiles = [...(files || []), ...Array.from(fileList).filter(filterAccept(accept))];
      if (!allowDuplicates) {
        const set = new Set();
        newFiles = newFiles.filter((file) => {
          const key = `${file.name}-${file.size}`;
          return set.has(key) ? false : (set.add(key), true);
        });
      }
      if (maxLength && newFiles.length > maxLength) {
        newFiles = newFiles.slice(0, maxLength);
      }
      if (maxSize) {
        const size = newFiles.reduce((accumulator, file) => accumulator + file.size, 0);
        if (size > maxSize) {
          return;
        }
      }
      onChange(newFiles);
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateFiles(event.target?.files);
  };

  const handleDraggableZoneFileDrop: DragEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const fileList = event.dataTransfer?.files;
    updateFiles(fileList);
  };

  const handleRemoveFile = (file: File) => {
    if (!files || files.length === 0) {
      return;
    }
    const index = files.indexOf(file);
    if (index !== -1) {
      files.splice(index, 1);
      const newFiles = [...files];
      onChange(newFiles);
    }
  };

  useEffect(() => {
    const handleDragOver = (event: MouseEvent) => {
      event.preventDefault();
      setIsDragging(true);
    };

    const handleDragLeave = (event: MouseEvent) => {
      event.preventDefault();
      setIsDragging(false);
    };

    const handleFileDrop = (event: MouseEvent) => {
      event.preventDefault();
      setIsDragging(false);
    };

    window.addEventListener('dragover', handleDragOver);
    window.addEventListener('dragleave', handleDragLeave);
    window.addEventListener('drop', handleFileDrop);
    return () => {
      window.removeEventListener('dragover', handleDragOver);
      window.removeEventListener('dragleave', handleDragLeave);
      window.removeEventListener('drop', handleFileDrop);
    };
  }, []);

  const dropZoneClassName = [
    isDraggingDebounced && 'file-select-dragging',
    errorText && 'validation-error',
  ]
    .filter((item) => item)
    .join(' ')
    .trim();

  const inputId = `file-select-input-overlay-${useId()}`;

  const handlePaste = useCallback(
    (e: ClipboardEvent) => {
      if (
        !disabled &&
        e.clipboardData?.files.length &&
        document.activeElement === inputRef.current
      ) {
        updateFiles(e.clipboardData.files);
      }
    },
    [disabled, updateFiles],
  );

  const handleLabelClick: MouseEventHandler<HTMLLabelElement> = useCallback(
    (e) => {
      if (allowPaste) {
        e.preventDefault();

        inputRef.current?.focus();
      }
    },
    [allowPaste],
  );

  const handleSelectFileClick = useCallback(() => {
    if (!allowPaste) return;

    inputRef.current?.showPicker();
  }, [allowPaste]);

  const mustShowActions = allowPaste || !hideActions;

  useEffect(() => {
    if (allowPaste) {
      document.addEventListener('paste', handlePaste);
    }

    return () => {
      document.removeEventListener('paste', handlePaste);
    };
  }, [allowPaste, handlePaste]);

  return (
    <div>
      <Box
        component="div"
        sx={[styles.fileSelectComponent, sx] as SxProps<Theme>}
        onDrop={handleDraggableZoneFileDrop}
        onDragOver={(e) => e.preventDefault()}
        onDragLeave={(e) => e.preventDefault()}
        className={dropZoneClassName}
      >
        <label
          htmlFor={inputId}
          className="file-select-input-overlay"
          onClick={handleLabelClick}
          aria-hidden="true"
        >
          <input
            ref={inputRef}
            id={inputId}
            data-testid="file-select"
            type="file"
            multiple={multiple}
            disabled={disabled || disabledActions}
            accept={accept?.join(', ')}
            value=""
            onChange={handleFileChange}
            style={{ width: 0 }}
          />
        </label>
        <Box sx={styles.contentPreviewList}>
          {placeholder && (previewPosition !== 'inbox' || !files?.length) && (
            <>
              {typeof placeholder === 'string' && (
                <Typography variant="body2" color="text.disabled">
                  {placeholder}
                </Typography>
              )}
              {typeof placeholder !== 'string' && placeholder}
            </>
          )}

          {previewPosition === 'inbox' && (
            <FilePreviewList
              disabled={disabled}
              files={files || []}
              previewMode={previewMode}
              onDelete={handleRemoveFile}
              errors={errors}
            />
          )}
        </Box>

        {(customActions || mustShowActions) && (
          <Box sx={styles.fileSelectActions}>
            {customActions}
            {mustShowActions && (
              <Button
                disabled={disabled || disabledActions}
                variant="outlined"
                component="label"
                htmlFor={inputId}
                onClick={handleSelectFileClick}
              >
                {selectFileButtonText}
              </Button>
            )}
          </Box>
        )}
        {!disabled && !disabledActions && (
          <div className="file-select-draggable-zone-overlay">
            <span>Перетягніть файл для завантаження...</span>
          </div>
        )}
      </Box>
      {errorText && <FormHelperText error>{errorText}</FormHelperText>}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}

      {previewPosition === 'bottom' && (
        <Box sx={styles.bottomFilePreviewList}>
          <FilePreviewList
            disabled={disabled}
            files={files || []}
            previewMode={previewMode}
            onDelete={handleRemoveFile}
            errors={errors}
          />
        </Box>
      )}
    </div>
  );
};

export { FilesSelect };
