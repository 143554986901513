import { SyntheticEvent, JSXElementConstructor, HTMLAttributes, ReactNode } from 'react';

import { Controller } from 'react-hook-form';

import {
  FormControl,
  FormHelperText,
  Autocomplete,
  ListItem,
  Checkbox,
  TextField,
  CircularProgress,
  type FormControlProps,
} from '@mui/material';

import { AutocompleteOption } from './types';
import { FormErrorMessage } from '../FormErrorMessage';

type Props<T extends string | number> = {
  name: string;
  label: string;
  limit?: number;
  options: AutocompleteOption<T>[];
  isLoading?: boolean;
  showErrors?: boolean;
  multipleErrors?: boolean;
  ListboxComponent?: JSXElementConstructor<HTMLAttributes<HTMLElement>>;
  onChange?: (event: SyntheticEvent, selectedOption: AutocompleteOption<T>[]) => void;
  renderCustomOptionAction?: (option: AutocompleteOption<T>) => ReactNode;
} & Omit<FormControlProps, 'onChange'>;

export const FormAutocompleteField = <T extends string | number>({
  name,
  label,
  options,
  limit = 1,
  isLoading = false,
  showErrors = true,
  multipleErrors = false,
  ListboxComponent,
  onChange,
  renderCustomOptionAction,
  ...rest
}: Props<T>) => {
  return (
    <Controller
      name={name}
      render={({
        field: { onChange: cOnChange, onBlur: cOnBlur, value: cValue, ref: cRef },
        fieldState: { invalid, error },
      }) => {
        const validationMessage = showErrors ? (
          <FormErrorMessage error={error} multiple={multipleErrors} />
        ) : null;
        return (
          <FormControl variant="outlined" fullWidth error={invalid} {...rest}>
            <Autocomplete
              multiple
              disableListWrap
              value={cValue}
              loading={isLoading}
              options={options}
              limitTags={limit}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                onChange?.(event, newValue);
                cOnChange(newValue);
              }}
              onBlur={cOnBlur}
              ListboxComponent={ListboxComponent}
              renderOption={(props, option, { selected }) => (
                <ListItem {...props} key={option.id}>
                  <Checkbox name={option.name} sx={{ p: 0, mr: 1 }} checked={selected} />
                  {renderCustomOptionAction?.(option)}
                  {option.name}
                </ListItem>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={label}
                  inputRef={cRef}
                  slotProps={{
                    input: {
                      ...params.InputProps,
                      onMouseDown: () => {},
                      endAdornment: (
                        <>
                          {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    },
                  }}
                />
              )}
              disableCloseOnSelect
              size="small"
              noOptionsText="Немає опцій"
            />
            {invalid && <FormHelperText error>{validationMessage}</FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
};
