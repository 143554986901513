import { filterEmptyParams } from './filterEmptyParams';
import { getSearchParams } from './getSearchParams';
import { SearchQueryParam } from '../types/SearchQueryParam';
import { SearchQueryParamSchema } from '../types/SearchQueryParamSchema';

export const getSearchesParams = <T extends object>(
  searchParams: URLSearchParams,
  params: SearchQueryParam<T>[] | SearchQueryParamSchema<T>,
) => {
  const queryParams = getSearchParams(searchParams, params);
  return filterEmptyParams(queryParams);
};
