import { SyntheticEvent, useState } from 'react';

interface ISnackbarProps {
  onClose?: (event?: SyntheticEvent | Event, reason?: string) => void;
  autoHideDuration?: number;
  open: boolean;
  anchorOrigin?: { vertical: 'top' | 'bottom'; horizontal: 'left' | 'center' | 'right' };
}

interface IUseSnackbarReturn {
  open: boolean;
  showSnackbar: () => void;
  closeSnackbar: ISnackbarProps['onClose'];
}

const useSnackbarMessage = (): IUseSnackbarReturn => {
  const [open, setOpen] = useState(false);

  const showSnackbar = (): void => {
    setOpen(true);
  };

  const closeSnackbar: ISnackbarProps['onClose'] = (
    event?: SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return { open, showSnackbar, closeSnackbar };
};

export default useSnackbarMessage;
