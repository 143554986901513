import { FC } from 'react';

import { type IconButtonProps, CircularProgress, IconButton } from '@mui/material';

const styles = {
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-15px',
    marginLeft: '-15px',
  },
};

type Props = IconButtonProps & {
  loading?: boolean;
};

export const LoadingIconButton: FC<Props> = ({ loading = false, children, ...buttonProps }) => {
  return (
    <IconButton {...buttonProps}>
      {children}
      {loading && <CircularProgress size={30} thickness={1} sx={styles.buttonProgress} />}
    </IconButton>
  );
};
