import { FC, PropsWithChildren, useEffect, useState } from 'react';

import CleaningServicesOutlined from '@mui/icons-material/CleaningServicesOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {
  Button,
  Chip,
  Drawer,
  Paper,
  Stack,
  Tooltip,
  buttonClasses,
  type SxProps,
  type DrawerProps,
} from '@mui/material';

import { isDarkMode } from '../../utils/isDarkMode';

const styles: Record<string, SxProps> = {
  buttonPaper: {
    display: 'inline-block',
    [`.${buttonClasses.root}`]: {
      p: 0.75,
      px: 1.5,
      color: 'text.primary',
    },
  },
  buttonClean: {
    margin: 0,
    minWidth: '20px',
    alignItems: 'center',
    '&.MuiButton-root': {
      paddingTop: 1,
      paddingBottom: 1,
    },
    '& .MuiButton-startIcon': {
      margin: 0,
    },
  },
  filtersCount: { bgcolor: 'primary.main', color: 'primary.contrastText', ml: 0.5 },
};

type Props = DrawerProps &
  Partial<{
    filtersCount: number;
    onOpen: (open: boolean) => void;
    onReset: () => void;
  }>;

export const FilterDrawerControl: FC<PropsWithChildren<Props>> = ({
  open,
  container,
  hideBackdrop,
  filtersCount,
  children,
  sx,
  onOpen,
  onReset,
}) => {
  const [openDrawer, setOpenDrawer] = useState(open);

  useEffect(() => {
    setOpenDrawer(open);
  }, [open]);

  const filtersButtonColor = filtersCount
    ? isDarkMode('warning.main', 'warning.dark')
    : 'primary.main';

  const handleOpenDrawerButtonClick = () => {
    onOpen?.(true);
    setOpenDrawer(true);
  };

  return (
    <>
      <Stack direction="row" spacing={1} className="FilterDrawerControl-root">
        <Paper sx={styles.buttonPaper}>
          <Button
            sx={{ color: filtersButtonColor }}
            startIcon={<FilterAltIcon />}
            size="small"
            onClick={handleOpenDrawerButtonClick}
          >
            {!filtersCount && 'Фільтрувати'}
            {!!filtersCount && (
              <>
                Фільтри:
                <Chip label={filtersCount} sx={styles.filtersCount} size="small" />
              </>
            )}
          </Button>
        </Paper>
        {!!filtersCount && onReset && (
          <Paper sx={styles.buttonPaper}>
            <Tooltip title="Скинути усі фільтри" placement="bottom">
              <Button
                sx={styles.buttonClean}
                size="small"
                startIcon={<CleaningServicesOutlined />}
                onClick={onReset}
              />
            </Tooltip>
          </Paper>
        )}
      </Stack>
      <Drawer
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        PaperProps={{ style: { position: 'absolute', overflowY: 'initial' } }}
        slotProps={{ backdrop: { style: { position: 'absolute' } } }}
        ModalProps={{ container, style: { position: 'absolute' } }}
        hideBackdrop={hideBackdrop}
        sx={sx}
      >
        {children}
      </Drawer>
    </>
  );
};
