import dayjs from 'dayjs';

import { normalizeSearchParamsSchema } from './normalizeSearchParamsSchema';
import { SearchQueryParam } from '../types/SearchQueryParam';
import { SearchQueryParamSchema } from '../types/SearchQueryParamSchema';

export const toURLSearchParams = <T extends object>(
  object: T,
  schema: SearchQueryParam<T>[] | SearchQueryParamSchema<T>,
): URLSearchParams => {
  const result = new URLSearchParams();
  const schemaArray = normalizeSearchParamsSchema(schema);

  schemaArray.forEach(({ name, type }) => {
    const value = object[name];
    if (
      value !== null &&
      value !== undefined &&
      value !== '' &&
      !(Array.isArray(value) && !value.length)
    ) {
      switch (type) {
        case 'date':
          result.set(name.toString(), dayjs(value as string | Date).format('YYYY-MM-DD'));
          break;
        case 'datetime':
          result.set(name.toString(), dayjs(value as string | Date).format());
          break;
        default:
          result.set(name.toString(), value.toString());
          break;
      }
    }
  });

  return result;
};
