import { FC } from 'react';

import BugReportIcon from '@mui/icons-material/BugReport';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import { Box, Chip } from '@mui/material';

import { FeedbackType } from './types';

const styles = {
  middleSizeSwitchButton: {
    flexDirection: 'column',
    fontSize: '16px',
    width: 100,
    height: 100,
    '& .MuiSvgIcon-root': { width: 40, height: 40, m: 0 },
  },
};

const buttons = [
  {
    icon: <BugReportIcon />,
    label: 'Проблема',
    value: FeedbackType.issue,
  },
  {
    icon: <NewReleasesIcon />,
    label: 'Ідея',
    value: FeedbackType.idea,
  },
  {
    icon: <ContactSupportIcon />,
    label: 'Інше',
    value: FeedbackType.other,
  },
];

type Props = {
  selected: FeedbackType | null;
  size: 'small' | 'middle';
  onChange: (value: FeedbackType) => void;
};

const FeedbackTypeSwitch: FC<Props> = ({ selected, size, onChange }) => {
  return (
    <Box sx={{ display: 'flex', gap: 2 }}>
      {buttons.map((button) => (
        <Chip
          key={button.value}
          sx={size === 'middle' ? styles.middleSizeSwitchButton : {}}
          icon={button.icon}
          label={button.label}
          color={button.value === selected ? 'primary' : 'default'}
          onClick={() => onChange(button.value)}
        />
      ))}
    </Box>
  );
};

export default FeedbackTypeSwitch;
