export const objectToFormData: <T extends object>(object: T) => FormData = (object) => {
  const formData = new FormData();
  const appendValue = (key: string, value: unknown) => {
    if (typeof value === 'undefined') {
      return;
    }
    if (value instanceof File || value instanceof Blob) {
      formData.append(key, value);
      return;
    }
    if (typeof value === 'object') {
      formData.append(key, JSON.stringify(value));
      return;
    }
    formData.append(key, value as string);
  };
  Object.entries(object).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        appendValue(key, item);
      });
    } else {
      appendValue(key, value);
    }
  });

  return formData;
};
