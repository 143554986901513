import { PropsWithChildren, FC, useState, useEffect } from 'react';

import classNames from 'classnames';

import CloseIcon from '@mui/icons-material/Close';
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
} from '@mui/material';

import { ThemeMode } from 'contexts/ThemeContext/types';
import { Styles } from 'types/theme.type';

import { BasicDialogProps } from './types';

const styles: Styles = {
  dialogTitle: { m: 0, p: 2, minHeight: '64px', paddingRight: '54px', fontWeight: 600 },
  closeButton: { position: 'absolute', right: 12, top: 12 },
  dialogActions: { p: 2, gap: 1 },
};

const BasicDialogTemplate: FC<PropsWithChildren<BasicDialogProps>> = ({
  open,
  ariaLabel,
  ariaDescription,
  children,
  maxWidth,
  sx,
  title = '',
  contentDividers = true,
  fullWidth = false,
  fullScreen = false,
  disableEscapeKeyDown = false,
  hideHeader = false,
  hideHeaderCloseButton = false,
  hideBackdrop = false,
  closeAfterTransition,
  closeOnBackdropClick = true,
  transitionDuration,

  handleClose,
  disableRestoreFocus,
  actions,
  ...rest
}) => {
  const theme = useTheme();
  const [openModal, setOpenModal] = useState(open);
  useEffect(() => setOpenModal(open), [open]);

  const handleDialogClose = (_e: unknown, reason?: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick' && !closeOnBackdropClick) {
      return;
    }
    handleClose();
  };

  return (
    <Dialog
      sx={sx}
      className={classNames('BasicDialog-root', {
        'MUI-theme-mode-dark': theme.palette.mode === ThemeMode.dark,
      })}
      open={openModal}
      onClose={handleDialogClose}
      disableEscapeKeyDown={disableEscapeKeyDown}
      disableRestoreFocus={disableRestoreFocus}
      aria-labelledby={ariaLabel}
      aria-describedby={ariaDescription}
      fullWidth={fullWidth}
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      closeAfterTransition={closeAfterTransition}
      hideBackdrop={hideBackdrop}
      transitionDuration={transitionDuration}
      {...rest}
    >
      {!hideHeader && (
        <DialogTitle className="BasicDialog-title" sx={styles.dialogTitle}>
          {title}
          {!hideHeaderCloseButton && (
            <IconButton aria-label="close" onClick={handleDialogClose} sx={styles.closeButton}>
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
      )}

      <DialogContent dividers={contentDividers}>{children}</DialogContent>
      {actions && <DialogActions sx={styles.dialogActions}>{actions}</DialogActions>}
    </Dialog>
  );
};

export default BasicDialogTemplate;
