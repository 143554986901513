import Plausible, { EventOptions, PlausibleOptions } from 'plausible-tracker';

import { useGetCurrentUserQuery } from 'api/services/account';
import { PRODUCTION } from 'constants/env';
import { useAuthContextProvider } from 'contexts/AuthContext/useAuthContextProvider';
import { PlausibleEvents, PlausiblePageView } from 'enums/plausibleAnalytics';

const PLAUSIBLE_DOMAIN = process.env.REACT_APP_PLAUSIBLE_DOMAIN ?? 'staging.osint.band';
const PLAUSIBLE_API_HOST = process.env.REACT_APP_PLAUSIBLE_API_HOST ?? 'https://plausible.io';

type PlausibleEventName = PlausibleEvents | PlausiblePageView;

export const usePlausibleAnalytics = () => {
  const auth = useAuthContextProvider();
  const { data: currentUser } = useGetCurrentUserQuery(undefined, { skip: !auth?.isAuthenticated });
  const { enableAutoPageviews, trackEvent } = Plausible({
    trackLocalhost: false,
    domain: PLAUSIBLE_DOMAIN,
    apiHost: PLAUSIBLE_API_HOST,
  });

  const { idMd5, organizationId } = currentUser ?? {};

  const plausibleEvent = (
    name: PlausibleEventName,
    options?: EventOptions,
    eventData?: PlausibleOptions
  ): void => {
    const isProduction = process.env.REACT_APP_ENVIRONMENT === PRODUCTION;
    if (!isProduction) return;

    const deanonymizeOptions = {
      props: {
        userMd5Id: idMd5?.slice(-7) ?? '',
        organizationId: organizationId ?? '',
        ...options?.props,
      },
    };

    trackEvent(name, deanonymizeOptions, eventData);
  };

  return { plausibleEvent, enableAutoPageviews };
};
