import { BaseQueryFn, MutationDefinition } from '@reduxjs/toolkit/dist/query';
// eslint-disable-next-line import/no-unresolved
import { MutationActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate';

import { NOTIFICATION } from 'components/Alert/constants';
import { showSuccessMessage } from 'components/Alert/utils';
import { displayErrorMessages } from 'utils';

export const mutationTriggerDefaultHandler = <
  QueryArg,
  BaseQuery extends BaseQueryFn,
  TagTypes extends string,
  ResultType
>(
  trigger: MutationActionCreatorResult<
    MutationDefinition<QueryArg, BaseQuery, TagTypes, ResultType>
  >,
  successMessage?: string,
  defaultErrorMessage?: string
) => {
  return trigger
    .unwrap()
    .then((data) => {
      if (successMessage) {
        showSuccessMessage(successMessage);
      }
      return data;
    })
    .catch((error) => {
      displayErrorMessages(error, defaultErrorMessage || NOTIFICATION.SOMETHING_WRONG);
      return error;
    });
};

export const toFormData: <T extends object>(object: T) => FormData = (object) => {
  const formData = new FormData();
  const appendValue = (key: string, value: unknown) => {
    if (typeof value === 'undefined') {
      return;
    }
    if (value instanceof File || value instanceof Blob) {
      formData.append(key, value);
      return;
    }
    if (typeof value === 'object') {
      formData.append(key, JSON.stringify(value));
      return;
    }
    formData.append(key, value as string);
  };
  Object.entries(object).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        appendValue(key, item);
      });
    } else {
      appendValue(key, value);
    }
  });

  return formData;
};
