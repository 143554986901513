import { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';

import { useLocalStorage } from 'usehooks-ts';

import {
  createTheme,
  type ThemeOptions,
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
} from '@mui/material';

import { GlobalStyles } from './GlobalStyles';
import palette from './palette';
import { ThemeMode } from './types';

export const THEME_MODE_STORAGE_KEY = 'settings.theme.mode';

const defaultTheme: ThemeOptions = {
  typography: {
    button: {
      textTransform: 'none',
      fontWeight: '600',
    },
  },
};

export const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const [themeMode, setThemeMode] = useState<ThemeMode.light | ThemeMode.dark | null>(null);
  const [themeModeStorage] = useLocalStorage(THEME_MODE_STORAGE_KEY, ThemeMode.light, {
    initializeWithValue: true,
  });
  useEffect(() => {
    const changeListener = (event: { matches: boolean }) => {
      const newColorScheme = event.matches ? ThemeMode.dark : ThemeMode.light;
      setThemeMode(newColorScheme);
    };
    let mode = themeModeStorage === ThemeMode.dark ? ThemeMode.dark : ThemeMode.light;
    const darkModeMediaSelector = '(prefers-color-scheme: dark)';
    if (themeModeStorage === ThemeMode.system) {
      mode = window.matchMedia?.(darkModeMediaSelector).matches ? ThemeMode.dark : ThemeMode.light;
      window.matchMedia?.(darkModeMediaSelector).addEventListener('change', changeListener);
    }
    setThemeMode(mode);

    return () => {
      window.matchMedia?.(darkModeMediaSelector).removeEventListener('change', changeListener);
    };
  }, [themeModeStorage]);

  const theme = useMemo(() => {
    if (!themeMode) return null;
    return createTheme({ palette: palette(themeMode), ...defaultTheme, cssVariables: true });
  }, [themeMode]);

  if (!theme) {
    return null;
  }
  return (
    <MuiThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <GlobalStyles />
        <CssBaseline />
        {children}
      </StyledEngineProvider>
    </MuiThemeProvider>
  );
};
