import { FC } from 'react';

import useBreadcrumbs, { type BreadcrumbData } from 'use-react-router-breadcrumbs';

import { Breadcrumbs as RouterBreadcrumbs, type SxProps } from '@mui/material';

import { BreadcrumbItem } from './BreadcrumbItem';

const styles: Record<string, SxProps> = {
  breadcrumbs: {
    width: 'max-content',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline',

    '& .MuiBreadcrumbs-ol': {
      flexWrap: 'nowrap',
      display: 'flex',
      alignItems: 'center',
      textOverflow: 'ellipsis',
      verticalAlign: 'text-top',
      '.MuiBreadcrumbs-li': {
        display: 'inline',
      },
      '.MuiBreadcrumbs-separator': {
        display: 'inline',
      },
    },
    '& .MuiBreadcrumbs-li:last-of-type': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
};

export const Breadcrumbs: FC = () => {
  const breadcrumbs: BreadcrumbData<string>[] = useBreadcrumbs();

  return (
    <RouterBreadcrumbs sx={styles.breadcrumbs} aria-label="breadcrumb">
      {breadcrumbs.map(({ match, breadcrumb }, index) => (
        <BreadcrumbItem
          key={match.pathname}
          isLast={index === breadcrumbs.length - 1}
          breadcrumb={breadcrumb}
          to={match.pathname}
        />
      ))}
    </RouterBreadcrumbs>
  );
};
