import { useState, useEffect, FC, PropsWithChildren } from 'react';

import { Backdrop, CircularProgress, type Theme } from '@mui/material';

const styles = {
  backdrop: (theme: Theme) => ({
    zIndex: theme.zIndex.drawer + 1,
  }),
};

type Props = {
  open: boolean;
  delay?: number;
  className?: string;
};

export const FullScreenLoader: FC<PropsWithChildren<Props>> = ({
  children,
  open = false,
  delay = 0,
  className = '',
}) => {
  const [showLoader, setShowLoader] = useState<boolean>(false);

  useEffect(() => {
    let delayTimeout;
    if (!open) {
      setShowLoader(open);
    } else if (!delay) {
      setShowLoader(open);
    } else {
      delayTimeout = setTimeout(() => {
        setShowLoader(true);
      }, delay);
    }

    return () => clearTimeout(delayTimeout);
  }, [open]);

  if (!showLoader) return null;
  return (
    <Backdrop open className={className} sx={styles.backdrop}>
      {children || <CircularProgress color="primary" />}
    </Backdrop>
  );
};
