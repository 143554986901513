import dayjs, { Dayjs } from 'dayjs';

import { Theme } from '@mui/material';

import { RTKQueryRes, SuccessResult, ErrorResponse } from 'types/api.type';

import { processServerErrorResponse } from './errors';

export const isObjectEmpty = (obj: object): boolean => Object.keys(obj).length === 0;

export const formatDate = (date: string | Date | null, options: Intl.DateTimeFormatOptions) => {
  if (!date) return '';
  return new Date(date).toLocaleDateString('uk-UA', options);
};

export const cleanEmptyFields = <T extends object>(data: T): T => {
  return Object.entries(data).reduce((acc, [key, value]) => {
    if (value !== '' && value !== null && value !== undefined) {
      return { ...acc, [key]: value };
    }
    return acc;
  }, {} as T);
};

export const handleApiCall = <T>(
  res: RTKQueryRes<T>,
  onError: (error: ErrorResponse) => void,
  onSuccess?: (res: SuccessResult<T>) => void
) => {
  if ('error' in res) {
    onError(res.error as ErrorResponse);
    return;
  }
  if (onSuccess) {
    onSuccess(res);
  }
};

export const displayErrorMessages = processServerErrorResponse;

export const setFullDate = (date: Dayjs | Date | string): string => {
  if (!date) return '';
  const updatedDate = dayjs(date).endOf('day').format();
  return updatedDate;
};

export const sortByDate = <T>(arr: T[], key: keyof T, direction: 'asc' | 'dsc'): T[] => {
  const dscSorted = [...arr].sort(
    (a, b) => new Date(b[key] as Date).getTime() - new Date(a[key] as Date).getTime()
  );
  const res = direction === 'dsc' ? dscSorted : dscSorted.reverse();
  return res;
};

export const phoneNumberAutoFormat = (phoneNumber: string): string => {
  const COUNTRY_CODE_URK = /^380/;

  const number = phoneNumber.trim().replace(/[^\d]/g, '');

  if (number.length === 12 && COUNTRY_CODE_URK.test(number))
    return number.replace(/(\d{2})(\d{3})(\d{1})/, '+$1 $2 $3');

  if (number.length < 4) return number;
  if (number.length < 7) return number.replace(/(\d{2})(\d{2})/, '$1 $2');
  if (number.length < 11) return number.replace(/(\d{1})(\d{3})(\d{1})/, '+$1 $2 $3');
  return number.replace(/(\d{1})(\d{3})(\d{4})/, '+$1 $2 $3');
};

export const providesListTag = <R extends { id: string | number }[], T extends string>(
  resultsWithIds: R | undefined,
  tagType: T
) => {
  return resultsWithIds
    ? [{ type: tagType, id: 'LIST' }, ...resultsWithIds.map(({ id }) => ({ type: tagType, id }))]
    : [{ type: tagType, id: 'LIST' }];
};

export const isDarkMode = (darkValue: string | undefined, lightValue: string | undefined = '') => {
  return (theme: Theme) => (theme.palette.mode === 'dark' ? darkValue : lightValue);
};

export const getFullName = <T>(values: T[]) => values.filter(Boolean).join(' ');

export const isUrl = (string: string) => {
  return ['http://', 'https://', 'www.'].some((el) => string.includes(el));
};
