import { sizeInMb } from '../utils/sizeInMb';

export function filesSizeValidation(maxFilesSizeInMb: number) {
  return function validator(files?: File[]): boolean {
    if (files) {
      const size = files.reduce((accumulator, file) => accumulator + file.size, 0);
      const filesSizeInMb = sizeInMb(size);
      if (filesSizeInMb > maxFilesSizeInMb) {
        return false;
      }
    }
    return true;
  };
}
