import { omit, pick } from 'lodash';

import { api } from 'api';
import { toFormData } from 'api/utils';
import { DossierFileType, SourceId } from 'enums/dossier';
import { ErrorData, FetchErrorData, GetParams, PaginatedResponse } from 'types/api.type';
import {
  DossierResponse,
  DossierUpdatePayload,
  CreateSocialConnector,
  DossierEnumOptionsList,
  DossierConnector,
  GetDossierRelatedTagsParams,
  GetDossierPhotoRelatedTagsParams,
  AvatarInfo,
  DossiersResponse,
  UpdateDossierRelatedTagsRequest,
  UpdateUserDossierRequest,
  UpdateDossierAndTagsRequest,
  DossierCreatePayload,
} from 'types/dossier.type';
import { NaiVisit } from 'types/personProfile.type';
import { BandTag } from 'types/tags.type';

const transformConnectorPayload = (payload: CreateSocialConnector) => {
  const fields: (keyof Pick<CreateSocialConnector, 'profileId' | 'link' | 'password' | 'login'>)[] =
    ['profileId', 'link', 'password', 'login'];
  fields.forEach((field) => {
    if (typeof payload[field] !== 'undefined' && !payload[field]) {
      payload[field] = null;
    }
  });
  return payload;
};

const updateDossierRelatedTagsInvalidatesTags = (
  _err: unknown,
  _res: unknown,
  arg: UpdateDossierRelatedTagsRequest
) =>
  [
    'userTags',
    'searches',
    'searchUsers',
    'locationDossiers',
    'intersections',
    'searchByNumbersDossiers',
    'profilesGroupPersonsList',
    'user',
    'dossierSearch',
    'dossierChanges',
    { type: 'dossierRelatedTags', id: arg.dossierId },
    { type: 'dossier', id: arg.dossierId },
  ] as const;

const getUpdateDossierRelatedTagsQueryParams = ({
  dossierId,
  ...payload
}: UpdateDossierRelatedTagsRequest) => ({
  url: `/dossier/${dossierId}/tags`,
  method: 'PUT',
  body: payload,
});

const getUpdateUserDossierQueryParams = ({ dossierId, ...payload }: UpdateUserDossierRequest) => ({
  url: `/dossier/${dossierId}`,
  method: 'PATCH',
  body: payload,
});

const dossierApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDossiers: build.query<
      PaginatedResponse<DossiersResponse>,
      GetParams & { ids: number[]; fields: string[] }
    >({
      query: (params) => ({
        url: '/dossiers',
        params,
      }),
      keepUnusedDataFor: 180,
      transformErrorResponse: (error) => error.data as ErrorData,
    }),

    getDossier: build.query<DossierResponse, number>({
      query: (dossierId) => `/dossier/${dossierId}`,
      keepUnusedDataFor: 180,
      providesTags: (_res, _err, id) => [{ type: 'dossier', id }],
      transformErrorResponse: (error) => error.data as ErrorData,
    }),

    updateDossier: build.mutation<DossierResponse, DossierUpdatePayload>({
      query: ({ id, ...payload }) => ({
        url: `/dossier/${id}`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (_res, _err, arg) => [
        { type: 'dossier', id: arg.id },
        { type: 'dossierPhotos', id: arg.id },
        'dossiers',
        'dossierSearch',
        'profilesGroupPersonsList',
        'dossierChanges',
      ],
    }),

    createDossier: build.mutation<DossierResponse, DossierCreatePayload>({
      query: (payload) => ({
        url: '/dossier',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['bandByConnector'],
    }),

    getDossierOptions: build.query<DossierEnumOptionsList, void>({
      query: () => `/dossier_options`,
    }),

    getDossierRelatedTags: build.query<BandTag[], GetDossierRelatedTagsParams>({
      query: ({ id, ...params }) => ({
        url: `/dossier/${id}/tags`,
        params,
      }),
      transformResponse({ tags }) {
        return tags;
      },
      providesTags: (_res, _err, arg) => {
        return [{ type: 'dossierRelatedTags', id: arg.id }];
      },
    }),

    updateDossierRelatedTags: build.mutation<BandTag, UpdateDossierRelatedTagsRequest>({
      query: getUpdateDossierRelatedTagsQueryParams,
      invalidatesTags: updateDossierRelatedTagsInvalidatesTags,
    }),

    updateDossierAndTags: build.mutation<null, UpdateDossierAndTagsRequest>({
      queryFn: async (payload, _queryApi, _extraOptions, baseQuery) => {
        const results = await Promise.all([
          baseQuery(getUpdateDossierRelatedTagsQueryParams(pick(payload, ['dossierId', 'tagIds']))),
          baseQuery(getUpdateUserDossierQueryParams(omit(payload, ['tagIds']))),
        ]);

        const error = results.find(({ error }) => error);

        return error ? { error: error as FetchErrorData } : { data: null };
      },
      invalidatesTags: updateDossierRelatedTagsInvalidatesTags,
    }),

    getDossierPhotos: build.query<
      PaginatedResponse<AvatarInfo>,
      GetParams & { id: number; photoSourceIds?: SourceId[]; tagIds?: number[] }
    >({
      query: ({ id, ...params }) => ({
        url: `/dossier/${id}/photos`,
        params,
      }),
      providesTags: (_err, _res, args) => [{ type: 'dossierPhotos', id: args.id }],
    }),

    getDossierPhotoRelatedTags: build.query<BandTag[], GetDossierPhotoRelatedTagsParams>({
      query: ({ dossierId, photoSourceId, photoId, ...params }) => ({
        url: `/dossier/${dossierId}/photo_source/${photoSourceId}/photo/${photoId}/tags`,
        params,
      }),
      transformResponse({ tags }) {
        return tags;
      },
      providesTags: ['dossierPhotoRelatedTags'],
    }),

    deleteDossierPhotoRelatedTag: build.mutation<
      void,
      { dossierId: number; photoId: number; photoSourceId: SourceId; tagId: number }
    >({
      query: ({ dossierId, photoId, photoSourceId, tagId }) => ({
        url: `/dossier/${dossierId}/photo_source/${photoSourceId}/photo/${photoId}/tag/${tagId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['dossierPhotoRelatedTags', 'dossierChanges'],
    }),

    updateUserDossier: build.mutation<void, UpdateUserDossierRequest>({
      query: getUpdateUserDossierQueryParams,
      invalidatesTags: (_res, _err, arg) => [
        { type: 'dossier', id: arg.dossierId },
        'dossiers',
        'dossierSearch',
        'profilesGroupPersonsList',
        'dossierChanges',
      ],
    }),

    updateUserDossierAvatarInfo: build.mutation<
      void,
      {
        dossierId: number;
      } & (
        | { avatarInfo: { id: number; sourceId: number } }
        | { avatarS3Info: { source: number; s3Path: string } }
      )
    >({
      query: ({ dossierId, ...payload }) => ({
        url: `/dossier/${dossierId}`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (_res, _err, arg) => [
        { type: 'dossier', id: arg.dossierId },
        'dossiers',
        'dossierSearch',
        'profilesGroupPersonsList',
        'dossierChanges',
      ],
    }),

    getDossierNais: build.query<PaginatedResponse<NaiVisit>, number>({
      query: (dossierId) => `/dossier/${dossierId}/nais`,
      providesTags: ['dossierNais'],
    }),

    getDossierConnectorList: build.query<PaginatedResponse<DossierConnector>, number>({
      query: (dossierId) => `/dossier/${dossierId}/connectors`,
      providesTags: (_res, _err, dossierId) => [{ type: 'dossierConnectors', id: dossierId }],
    }),

    addDossierConnector: build.mutation<
      void,
      Partial<CreateSocialConnector> & { dossierId: number }
    >({
      query: ({ dossierId, ...payload }) => ({
        url: `/dossier/${dossierId}/connector`,
        method: 'POST',
        body: transformConnectorPayload(payload as CreateSocialConnector),
      }),
      invalidatesTags: (_res, _err, args) => [
        { type: 'dossierConnectors', id: args.dossierId },
        'bandByConnector',
        'dossierChanges',
      ],
    }),

    updateDossierConnector: build.mutation<
      void,
      Partial<CreateSocialConnector> & { dossierId: number }
    >({
      query: ({ dossierId, ...payload }) => ({
        url: `/dossier/${dossierId}/connector/${payload.id}`,
        method: 'PATCH',
        body: transformConnectorPayload(payload as CreateSocialConnector),
      }),
      invalidatesTags: (_res, _err, args) => [
        { type: 'dossierConnectors', id: args.dossierId },
        'dossierChanges',
      ],
    }),

    deleteDossierConnector: build.mutation<void, { dossierId: number; connectorId: number }>({
      query: ({ dossierId, connectorId }) => ({
        url: `/dossier/${dossierId}/connector/${connectorId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_res, _err, args) => [
        { type: 'dossierConnectors', id: args.dossierId },
        'bandByConnector',
        'dossierChanges',
      ],
    }),

    uploadFiles: build.mutation<
      void,
      { dossierId: number; comment: string; files: File[]; fileType: DossierFileType }
    >({
      query: ({ dossierId, fileType, comment, files }) => {
        const body = toFormData({ comment, file: files });

        return {
          url: `/dossier/${dossierId}/note_type/${fileType}/files`,
          method: 'POST',
          headers: { 'Content-Type': undefined },
          body,
        };
      },

      extraOptions: { skipErrorResponseCamelize: true },
      invalidatesTags: (_res, _err, arg) => [
        { type: 'dossier', id: arg.dossierId },
        { type: 'dossierPhotos', id: arg.dossierId },
        'dossierChanges',
      ],
    }),

    deleteFile: build.mutation<
      void,
      { dossierId: number; fileId: number; fileType: DossierFileType }
    >({
      query: ({ dossierId, fileId, fileType }) => ({
        url: `/dossier/${dossierId}/note_type/${fileType}/files/${fileId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_res, _err, arg) => [
        { type: 'dossier', id: arg.dossierId },
        { type: 'dossierPhotos', id: arg.dossierId },
        'dossierChanges',
      ],
    }),
  }),
});

export const {
  useGetDossiersQuery,
  useGetDossierQuery,
  useUpdateDossierMutation,
  useCreateDossierMutation,
  /**
   * @deprecated
   * useUpdateUserDossierMutation
   */
  useUpdateUserDossierMutation,
  useUpdateUserDossierAvatarInfoMutation,

  useGetDossierOptionsQuery,
  useGetDossierPhotosQuery,
  useGetDossierRelatedTagsQuery,
  useLazyGetDossierRelatedTagsQuery,

  useGetDossierPhotoRelatedTagsQuery,
  useDeleteDossierPhotoRelatedTagMutation,

  useUpdateDossierRelatedTagsMutation,
  useUpdateDossierAndTagsMutation,

  useLazyGetDossierNaisQuery,
  useLazyGetDossierPhotosQuery,

  useGetDossierConnectorListQuery,
  useAddDossierConnectorMutation,
  useUpdateDossierConnectorMutation,
  useDeleteDossierConnectorMutation,

  useUploadFilesMutation,
  useDeleteFileMutation,
} = dossierApi;
