import html2canvas from 'html2canvas';

type TakeScreenshotOptions = {
  ignoreElements?: (element: Element) => boolean;
  type?: string;
  quality?: number;
};

export const takeScreenshot = (
  htmlElement: HTMLUnknownElement,
  options: TakeScreenshotOptions = {},
) => {
  return html2canvas(htmlElement, {
    allowTaint: true,
    useCORS: true,
    ignoreElements: options.ignoreElements,
  }).then((canvas: HTMLCanvasElement) => {
    const croppedCanvas = document.createElement('canvas');
    const croppedCanvasContext = croppedCanvas.getContext('2d');

    const cropPositionTop = 0;
    const cropPositionLeft = 0;
    const cropWidth = canvas.width;
    const cropHeight = canvas.height;
    croppedCanvas.width = cropWidth;
    croppedCanvas.height = cropHeight;
    croppedCanvasContext?.drawImage(canvas, cropPositionLeft, cropPositionTop);

    return new Promise<Blob>((resolve, reject) => {
      croppedCanvas.toBlob(
        (blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error('File conversion error'));
          }
        },
        options.type,
        options.quality,
      );
    });
  });
};
