export const isEqualURLSearchParams = (value: URLSearchParams, other: URLSearchParams) => {
  if (value.size !== other.size) {
    return false;
  }
  if (value.size === 0 && other.size === 0) {
    return true;
  }
  const newValue = new URLSearchParams(value);
  newValue.sort();
  const newOther = new URLSearchParams(other);
  newOther.sort();
  return newValue.toString() === newOther.toString();
};
