import {
  PropsWithChildren,
  createContext,
  useMemo,
  useState,
  useContext,
  SetStateAction,
  Dispatch,
  Context,
} from 'react';

import { omit } from 'lodash';

import { useNavigationFilter } from '../../hooks/useNavigationFilter';
import { SearchQueryParamSchema } from '../../types/SearchQueryParamSchema';

const IGNORE_IN_FILTERS_COUNT = ['page', 'pageSize'];

type ListFilterContextType<R, T extends object = object> = {
  filter: T;
  totalCount: number;
  filtersCount: number;
  data: R[];
  setTotalCount: (count: number) => void;
  setFilter: Dispatch<SetStateAction<T>>;
  resetFilter: () => void;
  resetPartialFilter: (fields: string[]) => void;
  setData: (items: R[]) => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ListFilterContext = createContext<ListFilterContextType<any, any>>({
  filter: {},
  setFilter: () => {},
  totalCount: 0,
  filtersCount: 0,
  data: [],
  setTotalCount: () => {},
  resetFilter: () => {},
  resetPartialFilter: () => {},
  setData: () => {},
});

type Props<T extends Record<string, unknown>> = {
  filterDefaultValues: T;
  filterInitialValues?: Partial<T>;
  filterQueryParamsSchema: SearchQueryParamSchema<T>;
};

export const ListFilterContextProvider = <T extends Record<string, unknown>, R>({
  children,
  filterDefaultValues,
  filterInitialValues,
  filterQueryParamsSchema,
}: PropsWithChildren<Props<T>>) => {
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState<R[]>([]);

  const navigationFilterInitialValues = useMemo(
    () => ({
      ...filterDefaultValues,
      ...filterInitialValues,
    }),
    [filterDefaultValues, filterInitialValues],
  );

  const { filter, setFilter } = useNavigationFilter<T>(
    filterQueryParamsSchema,
    navigationFilterInitialValues,
  );

  const filtersCount = useMemo(() => {
    return Object.keys(filterQueryParamsSchema)
      .filter((param) => !IGNORE_IN_FILTERS_COUNT.includes(param))
      .reduce((count, field) => {
        const item = filter[field];

        if (Array.isArray(item)) {
          return item.length ? count + 1 : count;
        }
        return filter[field] !== filterDefaultValues[field] ? count + 1 : count;
      }, 0);
  }, [filter, filterQueryParamsSchema, filterDefaultValues]);

  const resetFilter = () => setFilter(filterDefaultValues);

  const resetPartialFilter = (fields: string[]) => {
    setFilter({
      ...filterDefaultValues,
      ...omit(filter, ...fields),
    });
  };

  const value = useMemo(
    () => ({
      filter,
      setFilter,
      resetFilter,
      resetPartialFilter,
      filtersCount,
      totalCount,
      setTotalCount,
      data,
      setData,
    }),
    [filter, totalCount, data, filtersCount],
  );

  return <ListFilterContext.Provider value={value}>{children}</ListFilterContext.Provider>;
};

export const useListFilterContext = <T extends object, R = unknown>() => {
  const context = useContext(ListFilterContext as Context<ListFilterContextType<R, T>>);

  return context;
};
