export const CONFIRM_TEXT = {
  leave: {
    title: 'Вийти без збереження',
    description: 'Впевнені що хочете вийти без збереження нового списку тегів досьє?',
    submitButtonText: 'вийти без збереження',
    cancelButtonText: 'назад',
  },
  save: {
    title: 'Збереження змін',
    description: 'Зберегти внесені до тегів досьє зміни?',
    submitButtonText: 'зберегти',
    cancelButtonText: 'назад',
  },
} as const;
