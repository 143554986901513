import { FC } from 'react';

import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { Typography, Grid, Box, Stack } from '@mui/material';

import BasicDialogTemplate from 'components/BasicDialogTemplate';
import { Loading } from 'components/Loading';

import DialogActions from './components/DialogActions';
import SearchField from './components/SearchField';
import TagList from './components/TagList';
import UserInfo from './components/UserInfo';
import { useManageUserTags } from './ManageUserTags.hooks';
import { styles } from './styles';
import { ManageUserTagModalProps } from './types';

export const ManageUserTags: FC<ManageUserTagModalProps> = ({
  bandId,
  prevTagIdsState,
  onClose,
  onSave,
}) => {
  const {
    searchValue,
    userInfo,
    isUserLoading,
    isUserTagsLoading,
    selectedTags,
    matchedTags,
    isUserTagsUpdating,
    isUserTagsHasChanges,

    handleInputChange,
    handleTagClick,
    handleReset,
    handleClose,
    handleSave,
  } = useManageUserTags({
    bandId,
    prevTagIdsState,
  });

  const handleModalClose = () => {
    handleClose();
    onClose?.();
  };

  const handleSaveClick = async () => {
    const result = await handleSave();
    onSave?.();
    return result;
  };

  return (
    <BasicDialogTemplate
      open
      fullWidth
      disableRestoreFocus
      title="Редагування тегів досьє"
      ariaLabel="Edit band tags"
      sx={styles.dialog}
      handleClose={handleModalClose}
      actions={
        <DialogActions
          onClose={handleModalClose}
          onReset={handleReset}
          onSave={handleSaveClick}
          isLoading={isUserTagsUpdating}
          isDisabled={!isUserTagsHasChanges}
        />
      }
    >
      {!userInfo && !isUserLoading && (
        <Typography fontWeight="bold">{`Досьє з id ${bandId} не знайдено`}</Typography>
      )}
      {isUserLoading && <Loading sx={{ height: '100%' }} />}
      {!isUserLoading && userInfo && (
        <Grid container spacing={{ sx: 1, sm: 0 }} sx={{ height: 1 }}>
          <Grid item xs={12} sm={6} sx={{ height: 1 }}>
            <Stack gap={3} sx={{ height: 1 }}>
              <SearchField value={searchValue} onChange={handleInputChange} />
              <TagList tags={matchedTags} onClick={handleTagClick} />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={1} textAlign="center">
            <Box sx={styles.swapIconBox}>
              <SwapHorizIcon fontSize="large" color="disabled" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={5} sx={{ height: 1 }}>
            <Stack gap={3} sx={{ height: 1 }}>
              <UserInfo userInfo={userInfo} isUserLoading={isUserLoading} />
              {isUserTagsLoading && <Loading sx={{ height: '100%' }} />}
              {!isUserTagsLoading && !selectedTags.length && (
                <Typography variant="body1" color="text.secondary">
                  Оберіть теги для користувача
                </Typography>
              )}
              {selectedTags.length > 0 && (
                <TagList
                  tags={selectedTags}
                  canDelete={(tag) => tag.removable}
                  onClick={handleTagClick}
                />
              )}
            </Stack>
          </Grid>
        </Grid>
      )}
    </BasicDialogTemplate>
  );
};
