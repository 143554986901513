import { PropsWithChildren, FC, useEffect, createContext, useMemo, useContext } from 'react';

import Plausible, { type EventOptions, type PlausibleOptions } from 'plausible-tracker';
import { useAuth } from 'react-oidc-context';

type PlausibleAnalyticsType = {
  trackEvent: (eventName: string, options?: EventOptions, eventData?: PlausibleOptions) => void;
};

const PlausibleAnalyticsContext = createContext<PlausibleAnalyticsType>({
  trackEvent: () => {},
});

type Props = {
  domain: string;
  apiHost: string;
  autoPageviews?: boolean;
};

export const PlausibleAnalyticsProvider: FC<PropsWithChildren<Props>> = ({
  children,
  domain,
  apiHost,
  autoPageviews = false,
}) => {
  const auth = useAuth();

  const { enableAutoPageviews, trackEvent } = useMemo(() => {
    return Plausible({
      trackLocalhost: false,
      domain,
      apiHost,
    });
  }, [domain, apiHost]);

  useEffect(() => {
    if (!autoPageviews || !domain || !apiHost) return () => {};

    const cleanup = enableAutoPageviews();

    return () => {
      cleanup();
    };
  }, [autoPageviews]);

  const value = useMemo(() => {
    return {
      trackEvent: (eventName: string, options?: EventOptions, eventData?: PlausibleOptions) => {
        if (!domain || !apiHost) return;
        const newOptions = {
          ...options,
          props: {
            userId: auth?.user?.profile?.sub,
            ...options?.props,
          },
        };
        trackEvent(eventName, newOptions, eventData);
      },
    };
  }, [trackEvent, domain, apiHost, auth?.user?.profile?.sub]);

  return (
    <PlausibleAnalyticsContext.Provider value={value}>
      {children}
    </PlausibleAnalyticsContext.Provider>
  );
};

export const usePlausibleAnalytics = () => useContext(PlausibleAnalyticsContext);
