import { oidcUserManagerInstance } from '../oidc/oidcUserManagerInstance';

export function httpRequestAuthInterceptor(config) {
  return oidcUserManagerInstance
    .getUser()
    .then((user) => {
      config.headers = {
        'Content-Type': 'application/json',
        ...config.headers,
      };
      const accessToken = user?.access_token;
      if (accessToken) {
        config.headers = {
          Authorization: `Bearer ${accessToken}`,
          ...config.headers,
        };
      }
      return config;
    })
    .catch(() => Promise.resolve(config));
}
