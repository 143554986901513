import { useEffect, useState } from 'react';

import { isEqual, pickBy } from 'lodash';
import { useLocation, useSearchParams } from 'react-router-dom';

import { SearchQueryParamSchema } from '../types/SearchQueryParamSchema';
import { getSearchesParams } from '../utils/getSearchesParams';
import { isEqualURLSearchParams } from '../utils/isEqualURLSearchParams';
import { toURLSearchParams } from '../utils/toURLSearchParams';

export const useNavigationFilter = <T extends Record<string, unknown>>(
  filterQueryParamsSchema: SearchQueryParamSchema<T>,
  filterInitialValues?: T,
) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const navigationFilter = getSearchesParams<T>(searchParams, filterQueryParamsSchema);

  const [filter, setFilter] = useState<T>({
    ...filterInitialValues,
    ...navigationFilter,
  } as T);

  useEffect(() => {
    const newSearchesParams = getSearchesParams<T>(searchParams, filterQueryParamsSchema);
    const schemaKeys = Object.keys(filterQueryParamsSchema);
    const currentFilter = pickBy(filter, (_value, key) => schemaKeys.includes(key));

    const navigationInitialFilter = filterInitialValues
      ? pickBy(filterInitialValues, (_value, key) => schemaKeys.includes(key))
      : {};

    const newNavigationFilter = {
      ...navigationInitialFilter,
      ...newSearchesParams,
    };
    if (!isEqual(currentFilter, newNavigationFilter)) {
      setFilter({ ...filter, ...newNavigationFilter });
    }
  }, [searchParams.toString()]);

  useEffect(() => {
    const schemaKeys = Object.keys(filterQueryParamsSchema);
    const newNavigationFilters = pickBy(filter, (value, key) => {
      return value !== undefined && schemaKeys.includes(key);
    });
    const newNavigationFiltersFilteredDefault = filterInitialValues
      ? pickBy(newNavigationFilters, (value, key) => filterInitialValues[key] !== value)
      : {};
    const params = toURLSearchParams(newNavigationFiltersFilteredDefault, filterQueryParamsSchema);

    const restSearchParams = new URLSearchParams();
    searchParams.forEach((value, key) => {
      if (!schemaKeys.includes(key)) {
        restSearchParams.set(key, value);
      }
    });

    restSearchParams.forEach((value, key) => params.set(key, value));
    if (!isEqualURLSearchParams(searchParams, params)) {
      setSearchParams(params, { replace: false, state: location?.state });
    }
  }, [filter, filterInitialValues, filterQueryParamsSchema]);

  return {
    filter,
    setFilter,
  };
};
