import { DefaultThemeColors } from 'types/theme.type';

import { PRIORITY_TAG_MAX_VALUE } from './constants';

export const getBandTagChipColor = (
  priority: number,
  limit: number = PRIORITY_TAG_MAX_VALUE
): DefaultThemeColors => {
  const isPriorityTag = priority >= 0 && priority <= limit;

  return isPriorityTag ? 'warning' : 'default';
};
