import { createAsyncThunk } from '@reduxjs/toolkit';

import { accountApi } from 'api/services/account';
import { CurrentUser } from 'types/account.type';

export const PREFIX = 'account' as const;

export const currentUserLoad = createAsyncThunk(`${PREFIX}/get`, async (_, { dispatch }) => {
  const result = dispatch(accountApi.endpoints.getCurrentUser.initiate());
  result.unsubscribe();
  const response = await result;
  return response.data as CurrentUser;
});
