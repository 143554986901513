export const NOTIFICATION = {
  SOMETHING_WRONG:
    'Сталась помилка при запиті до сервера :(! Спробуйте повторити запит через деякий час.',
  TAG_USER_SUCCESS: 'Тег успішно збережно',
  NEW_SEARCH_SUCCESS: 'Пошук успішно створено!',
  ADD_POINTS_SUCCESS: 'Точки успішно додано!',
  ARCHIVE_DELETE_SUCCESS: 'Архів успішно видалено!',
  DOSSIER_UPDATE_SUCCESS: 'Досьє успішно оновлено!',
  MAP_RADIUS_POINT_WARNING: 'Оберіть радіус навкого точки або видаліть маркер',
  PERSON_DELTA_SET_MONITORING: 'Досьє доступне для Дельта моніторингу',
  PERSON_DELTA_UNSET_MONITORING: 'Досьє недоступне для Дельта моніторингу',
  PERSON_DELTA_VIP_STATUS_CHANGE: 'Статус VIP успішно змінено',
  NEW_SEARCH_NUMBERS_DUPLICATES_WARNING: 'Знайдено однакові номера телефонів',
  INVALID_COORDINATES: 'Введено некоректні координати.',
};
