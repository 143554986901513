import { ComponentProps, ReactNode, useCallback, useMemo, useState } from 'react';

import { Form, Formik, FormikErrors } from 'formik';
import * as yup from 'yup';
import { Schema } from 'yup';

import { Box, Button, SxProps, Theme, Typography } from '@mui/material';

import BasicDialogTemplate from 'components/BasicDialogTemplate';
import { useModal } from 'contexts/ModalContext';

import PromptDialogField from './PromptDialogField';
import { styles } from './styles';

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

type ResultType<T> = { [K in keyof T]: T[K] };

type FieldType<T, K extends keyof T> = {
  label: string;
  initialValue: T[K];
  type?: 'text' | 'password' | 'checkbox';
  helperText?: ReactNode;
  multiline?: boolean;
  minRows?: number;
  maxRows?: number;
  validation?: Schema;
  autoFocus?: boolean;
};

type Props<T extends object> = {
  fields: { [Key in keyof T]: FieldType<T, Key> };
  title?: string;
  description?: string | ReactNode;
  submitButtonText?: string | ReactNode;
  cancelButtonText?: string | ReactNode;
  awaitSubmitSuccess?: boolean;
  hideHeaderCloseButton?: boolean;
  sx?: ComponentProps<typeof BasicDialogTemplate>['sx'];

  onSubmit?: (value: ResultType<T>) => void;
  onCancel?: () => void;
};

const PromptDialog = <T extends ResultType<T>>({
  fields,
  title = '',
  description = '',
  cancelButtonText = 'Закрити',
  submitButtonText = 'Зберегти',
  awaitSubmitSuccess = false,
  hideHeaderCloseButton = false,
  sx = null,

  onSubmit = () => {},
  onCancel = () => {},
}: Props<T>) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { closeModal } = useModal();

  const handleCancel = useCallback(() => {
    closeModal();
    onCancel();
  }, [closeModal, onCancel]);

  const handleSubmit = useCallback(
    async (values: T, { setErrors }: { setErrors: (errors: FormikErrors<T>) => void }) => {
      if (!awaitSubmitSuccess) {
        closeModal();
        onSubmit(values);
        return;
      }
      try {
        setIsLoading(true);
        await onSubmit(values);
        closeModal();
      } catch (error) {
        if (error instanceof Error) {
          setErrors(error.cause as Partial<T>);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [closeModal, onSubmit]
  );

  const formikState = useMemo(() => {
    const initialValues = {} as T;
    const validationSchema = {};
    // @ts-ignore TS2322
    const entries: Entries<Props<T>['fields']> = Object.entries(fields);

    for (let i = 0; i < entries.length; i++) {
      const [name, field] = entries[i];
      initialValues[name] = field.initialValue;
      // @ts-ignore TS2536
      validationSchema[name] = field.validation;
    }
    return {
      entries,
      initialValues,
      validationSchema: yup.object().shape(validationSchema),
    };
  }, [fields]);

  const getAutoFocus = (fieldValue?: boolean) => {
    if (typeof fieldValue === 'boolean') {
      return fieldValue;
    }
    if (formikState.entries.length === 1) {
      return true;
    }
    return false;
  };

  return (
    <BasicDialogTemplate
      sx={[styles.dialog, sx] as SxProps<Theme>}
      title={title}
      open
      handleClose={handleCancel}
      disableRestoreFocus
      contentDividers={false}
      disableEscapeKeyDown={false}
      hideHeaderCloseButton={hideHeaderCloseButton}
    >
      <Box className="prompt-dialog-form-wrapper">
        {description && <Typography variant="body2">{description}</Typography>}
        <Formik
          initialValues={formikState.initialValues}
          validationSchema={formikState.validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, isValid, dirty, isInitialValid, handleChange, submitForm }) => (
            <Form className="prompt-dialog-form">
              {formikState.entries.map(([name, field]) => (
                <PromptDialogField
                  key={name.toString()}
                  name={name.toString()}
                  label={field.label}
                  type={field.type || 'text'}
                  multiline={field.multiline}
                  minRows={field.minRows}
                  maxRows={field.maxRows}
                  value={values[name] || ''}
                  onChange={handleChange}
                  autoFocus={getAutoFocus(field.autoFocus)}
                  helperText={field.helperText}
                />
              ))}
              <Box sx={styles.buttonGroup}>
                <Button variant="outlined" onClick={handleCancel} color="inherit">
                  {cancelButtonText}
                </Button>
                <Button
                  onClick={submitForm}
                  disabled={(!dirty && !isInitialValid) || !isValid || isLoading}
                  variant="contained"
                >
                  {submitButtonText}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </BasicDialogTemplate>
  );
};

export default PromptDialog;
