import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { resetAll } from 'api';
import { accountApi } from 'api/services/account';
import { LoadingStates } from 'enums';
import { CorePermission } from 'enums/corePermission';
import { AccountState } from 'types/account.type';

import { PREFIX } from './async';

const initialState: AccountState = {
  currentUser: null,
  status: LoadingStates.IDLE,
  error: null,
};

const slice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {
    applyPermissions(state, { payload: newPermissions }: PayloadAction<CorePermission[]>) {
      if (!state.currentUser) return state;
      return { ...state, currentUser: { ...state.currentUser, permissions: newPermissions } };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetAll, () => initialState)
      .addMatcher(accountApi.endpoints.getCurrentUser.matchFulfilled, (state, action) => {
        state.status = LoadingStates.FULFILLED;
        state.currentUser = action.payload;
      })
      .addMatcher(accountApi.endpoints.getCurrentUser.matchPending, (state) => {
        state.status = LoadingStates.PENDING;
        state.error = null;
      })
      .addMatcher(accountApi.endpoints.getCurrentUser.matchRejected, (state, action) => {
        state.status = LoadingStates.REJECTED;
        state.error = action.error.message || null;
        state.currentUser = null;
      });
  },
});

export const { applyPermissions } = slice.actions;

export default slice.reducer;
