import { FC, PropsWithChildren } from 'react';

import { ThemeProvider as OrnamentThemeProvider } from '@ornament/ui';

import GlobalStyles from './globalStyles';

export const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <OrnamentThemeProvider>
      <GlobalStyles />
      {children}
    </OrnamentThemeProvider>
  );
};
