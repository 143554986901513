import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector, type TypedUseSelectorHook } from 'react-redux';

import { api } from 'api';
import { redirectsHandler } from 'api/middlewares';
import { geocodingMapboxApi } from 'api/services/geocodingMapbox';
import { DEVELOPMENT } from 'constants/env';

import account, { applyPermissions } from './slices/account';
import breadcrumbs from './slices/breadcrumbs';
import layout from './slices/layout';

const isDevelopment = process.env.REACT_APP_ENVIRONMENT === DEVELOPMENT;

const devToolsOptions = {
  actionCreators: { applyPermissions },
};

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [geocodingMapboxApi.reducerPath]: geocodingMapboxApi.reducer,
    layout,
    account,
    breadcrumbs,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(api.middleware)
      .concat(geocodingMapboxApi.middleware)
      .concat(redirectsHandler),
  devTools: isDevelopment && devToolsOptions,
});

export type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
