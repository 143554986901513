import { FC, MouseEventHandler, useState } from 'react';

import { useCopyToClipboard } from 'usehooks-ts';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton, Tooltip } from '@mui/material';

type Props = {
  value: string | number;
  className?: string;
};

export const CopyToClipboardButton: FC<Props> = ({ value, className }) => {
  const [title, setTitle] = useState('');
  const [, copy] = useCopyToClipboard();

  const handleCopyClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    copy(`${value}`).then(() => {
      setTitle('скопійовано!');
      setTimeout(() => setTitle(''), 3000);
    });
  };

  return (
    <Tooltip title={title} open={!!title} placement="top">
      <IconButton onClick={handleCopyClick} size="small" className={className}>
        <ContentCopyIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};
