import { type Parser } from 'bowser';

export enum FeedbackType {
  issue = 0,
  idea = 1,
  other = 2,
}

export type SendFeedbackFormType = {
  type: FeedbackType | null;
  message: string;
  contact: string;
  files: File[];
  module?: string | null;
};

type Environment = Parser.ParsedResult & {
  screen: Partial<Pick<Screen, 'width' | 'height' | 'orientation'>>;
  path?: string;
  themeMode?: string;
};

export type SendFeedbackRequestType = { environment: Environment } & SendFeedbackFormType;
