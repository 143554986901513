import { FC } from 'react';

import ReplayIcon from '@mui/icons-material/Replay';
import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';

import { styles } from '../../styles';
import { UseManageUserTagsReturnType } from '../../types';

type DialogActionsProps = {
  isLoading: boolean;
  isDisabled: boolean;
  onClose: () => void;
  onReset: () => void;
  onSave: UseManageUserTagsReturnType['handleSave'];
};

export const DialogActions: FC<DialogActionsProps> = ({
  isLoading,
  isDisabled,
  onClose,
  onReset,
  onSave,
}) => {
  return (
    <Box sx={styles.buttonGroup}>
      <Button
        variant="text"
        color="secondary"
        aria-label="reset"
        startIcon={<ReplayIcon />}
        onClick={onReset}
      >
        Скинути зміни
      </Button>
      <Box sx={styles.box}>
        <Button variant="text" aria-label="cancel" onClick={onClose} sx={styles.buttonCancel}>
          Скасувати
        </Button>
        <LoadingButton
          variant="text"
          color="primary"
          aria-label="save"
          loading={isLoading}
          disabled={isDisabled}
          onClick={onSave}
        >
          Зберегти
        </LoadingButton>
      </Box>
    </Box>
  );
};
