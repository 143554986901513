import { FC } from 'react';

import {
  Box,
  CircularProgress,
  type CircularProgressProps,
  type BoxProps,
  type SxProps,
  type Theme,
} from '@mui/material';

interface Props extends BoxProps {
  color?: CircularProgressProps['color'];
}

const styles: Record<string, SxProps> = {
  loading: {
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export const Loader: FC<Props> = ({ color, sx, height, ...rest }) => (
  <Box sx={[styles.loading, sx, { height }] as SxProps<Theme>} {...rest}>
    <CircularProgress color={color} />
  </Box>
);
