import { FC, ReactNode, Suspense } from 'react';

import { Loading } from 'components/Loading';

type SuspenseWithLoadingProps = {
  children: ReactNode;
};

const SuspenseWithLoading: FC<SuspenseWithLoadingProps> = ({ children }) => (
  <Suspense fallback={<Loading />}>{children}</Suspense>
);

export default SuspenseWithLoading;
