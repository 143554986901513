import { LIMITS as USER_LIMITS } from 'constants/user';
import { RootState } from 'store';

const getAccount = (state: RootState) => state.account;

export const getAccountStatus = (state: RootState) => getAccount(state).status;
export const getAccountError = (state: RootState) => getAccount(state).error;

export const getCurrentUser = (state: RootState) => getAccount(state).currentUser;
export const getCurrentUserLimits = (state: RootState) => ({
  ...USER_LIMITS,
  ...getAccount(state).currentUser?.limits,
});
export const getUserPermissions = (state: RootState) => getAccount(state).currentUser?.permissions;

export const getUserOrganization = (state: RootState) =>
  getAccount(state).currentUser?.organizationId;

export const getUserType = (state: RootState) => getAccount(state).currentUser?.type;
