import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { camelizeKeys } from 'humps';

import { MAPBOX_GEOCODING_API } from 'constants/url';
import { GeocodingGetParams, GeocodingMapboxResponse } from 'types/geocodingMapbox.type';

export const geocodingMapboxApi = createApi({
  reducerPath: 'geocodingMapboxApi',
  baseQuery: fetchBaseQuery(),
  endpoints: () => ({}),
});

const geocodingMapboxApiHooks = geocodingMapboxApi.injectEndpoints({
  endpoints: (builder) => ({
    getGeoLocation: builder.query<GeocodingMapboxResponse, GeocodingGetParams>({
      query: ({ place, ...payload }) => ({
        url: `${MAPBOX_GEOCODING_API}/${place}.json`,
        params: {
          ...payload,
          access_token: process.env.REACT_APP_MAPBOX_TOKEN,
        },
      }),
      transformResponse(response: GeocodingMapboxResponse) {
        const camelizeFeatures = camelizeKeys(response.features ?? []);
        const transformData = {
          ...response,
          features: camelizeFeatures,
        } as GeocodingMapboxResponse;
        return transformData;
      },
    }),
  }),
});

export const { useGetGeoLocationQuery } = geocodingMapboxApiHooks;
