import { FC, PropsWithChildren, useCallback, useState, useMemo, useEffect } from 'react';

import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useAuth } from 'react-oidc-context';

import { resetAll } from 'api';
import { useGetCurrentUserQuery, useLazyGetCurrentUserQuery } from 'api/services/account';
import {
  useSignInMutation,
  useSignOutMutation,
  useVerifyMfaCodeMutation,
  useCreateMfaMutation,
  useDeleteMfaMutation,
} from 'api/services/auth';
import { showErrorMessage } from 'components/Alert/utils';
import { LOGIN_PAGE_URL, ERR_MSG } from 'constants/auth';
import { useAppDispatch } from 'store';
import { ErrorData } from 'types/api.type';
import { handleApiCall, displayErrorMessages } from 'utils';
import { processServerErrorResponse } from 'utils/errors';

import { AuthContext } from './AuthContext';
import { AuthContextType } from './types';

export const AuthContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const auth = useAuth();
  const [loginState, setLoginState] = useState<AuthContextType['loginState']>({
    inProgress: false,
    isAuthenticated: false,
  });

  const [signIn, { isLoading: isSignInLoading }] = useSignInMutation();
  const [signOut, { isLoading: isSignOutLoading }] = useSignOutMutation();
  const [verifyMfaCode, { isLoading: isVerifyMfaCodeLoading }] = useVerifyMfaCodeMutation();
  const { data: currentUser, isLoading: isCurrentUserLoading } = useGetCurrentUserQuery();
  const [triggerCurrentUser] = useLazyGetCurrentUserQuery();

  const [triggerCreateMfa] = useCreateMfaMutation();
  const [triggerDeleteMfa] = useDeleteMfaMutation();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (currentUser && loginState && !isCurrentUserLoading) {
      setLoginState({
        ...loginState,
        isAuthenticated: true,
      });
    }
  }, [currentUser, isCurrentUserLoading]);

  useEffect(() => {
    if (auth?.user?.profile?.sub) triggerCurrentUser();
  }, [auth?.user?.profile?.sub]);

  const deleteMfa = useCallback(async () => {
    await triggerDeleteMfa().unwrap();
  }, [triggerDeleteMfa]);

  const createMfa = useCallback(() => {
    return triggerCreateMfa().unwrap();
  }, [triggerCreateMfa]);

  const logout = useCallback(async () => {
    try {
      await signOut().unwrap();
      setLoginState({
        isAuthenticated: false,
        inProgress: false,
        error: null,
      });
    } catch (error) {
      processServerErrorResponse(error);
      setLoginState({
        isAuthenticated: false,
        inProgress: false,
        error: (error as FetchBaseQueryError)?.data as ErrorData,
      });
    }
    dispatch(resetAll());
    window.location.href = LOGIN_PAGE_URL;
  }, []);

  const login = useCallback(
    async (username: string, password: string) => {
      const result = await signIn({ username, password });

      handleApiCall(
        result,
        (err) => {
          setLoginState({
            ...loginState,
            isAuthenticated: false,
            inProgress: false,
            error: err.data,
          });
          showErrorMessage(ERR_MSG);
        },
        (res) => {
          let isAuthenticated = true;
          let inProgress = false;
          if (res.data?.isMfaAuthConfigured || res.data?.isMfaAuthEnforced) {
            isAuthenticated = false;
            inProgress = true;
          }
          setLoginState({
            isAuthenticated,
            inProgress,
            error: null,
            isMfaAuthConfigured: res.data?.isMfaAuthConfigured,
            isMfaAuthEnforced: res.data?.isMfaAuthEnforced,
          });
        }
      );
    },
    [logout, loginState]
  );

  const verifyOtpCode = useCallback(
    async (code: string) => {
      const result = await verifyMfaCode({ code });
      handleApiCall(
        result,
        (err) => {
          setLoginState({
            ...loginState,
            isAuthenticated: false,
            inProgress: true,
            error: err.data,
          });
          displayErrorMessages(err.data);
        },
        () => {
          setLoginState({
            ...loginState,
            isAuthenticated: true,
            inProgress: false,
            error: null,
          });
        }
      );
    },
    [loginState]
  );

  const authContextValue = useMemo(
    () => ({
      isAuthenticated: loginState?.isAuthenticated,
      loginState,
      login,
      logout,
      verifyOtpCode,
      deleteMfa,
      createMfa,
      isFetching: isCurrentUserLoading,
      isLoading: isSignInLoading || isSignOutLoading || isVerifyMfaCodeLoading,
    }),
    [
      loginState,
      login,
      logout,
      verifyOtpCode,
      deleteMfa,
      createMfa,
      isSignInLoading,
      isSignOutLoading,
      isVerifyMfaCodeLoading,
      isCurrentUserLoading,
    ]
  );

  return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
};
