import { useGetFlagsQuery } from 'api/services/account';
import { FLAGS } from 'constants/flags';

type NameType = (typeof FLAGS)[keyof typeof FLAGS];

export const useFlagEnabled = (
  names: NameType | NameType[],
  comparison?: 'AND' | 'OR'
): [boolean, { isLoading: boolean; isError: boolean }] => {
  const { data: enabledFlags, isLoading, isError } = useGetFlagsQuery();
  if (!Array.isArray(names)) {
    names = [names];
  }

  let result = false;
  if (enabledFlags) {
    const method = comparison === 'OR' ? 'some' : 'every';
    result = names[method]((flag) => enabledFlags.includes(flag as string));
  }
  return [result, { isLoading, isError }];
};
