import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
  useCallback,
  ComponentProps,
} from 'react';

import ConfirmDialog from 'modals/ConfirmDialog';
import PromptDialog from 'modals/PromptDialog';

import { ModalByName } from './data';
import { ModalNames } from './enums';
import { ModalContextType, ModalInstance } from './types';

const ModalContext = createContext<ModalContextType>({
  modal: null,
  openModal: () => {},
  closeModal: () => {},
});

const ModalProvider = ({ children }: PropsWithChildren) => {
  const [modal, setModal] = useState<ModalInstance<ModalNames> | null>(null);

  const openModal: ModalContextType['openModal'] = useCallback((type, props) => {
    setModal({ type, props });
  }, []);

  const closeModal = useCallback(() => {
    setModal(null);
  }, []);

  const contextValue = useMemo(
    () => ({ modal, openModal, closeModal }),
    [modal, openModal, closeModal]
  );

  const Component = useMemo(() => (modal?.type ? ModalByName[modal?.type] : null), [modal]);

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
      {modal && Component && <Component {...modal?.props} />}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);

export const useConfirmDialog = () => {
  const { openModal } = useModal();

  return (props: ComponentProps<typeof ConfirmDialog>) => {
    openModal(ModalNames.confirmDialog, props);
  };
};

export const usePromptDialog = <T extends object>() => {
  const { openModal } = useModal();

  return (props: ComponentProps<typeof PromptDialog<T>>) => {
    openModal(ModalNames.promptDialog, props);
  };
};

export default ModalProvider;
