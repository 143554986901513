import { useState } from 'react';

import axios, { type AxiosRequestConfig } from 'axios';

import { TransformResponseFunc } from '../types/TransformResponseFunc';

export type LazyQueryProps<R, P = undefined> = {
  query: (params?: P) => Omit<AxiosRequestConfig, 'transformResponse'> & TransformResponseFunc<R>;
};

type ResponseResult<T> = {
  data?: T;
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  error: { data?: { reason?: string }; message?: string } | null;
};

type UseLazyQueryResult<T, P> = [
  (params?: P, preferCacheValue?: boolean) => Promise<ResponseResult<T>>,
  ResponseResult<T>,
];

export const useLazyQuery = <R, P = undefined>({
  query,
}: LazyQueryProps<R, P>): UseLazyQueryResult<R, P> => {
  const [responseResult, setResponseResult] = useState<ResponseResult<R>>({
    data: undefined,
    isFetching: false,
    isLoading: false,
    isError: false,
    error: null,
  });

  const trigger = (params?: P) => {
    const isLoading = !responseResult.data;
    setResponseResult({
      isLoading,
      isFetching: true,
      isError: false,
      data: undefined,
      error: null,
    });
    return new Promise<ResponseResult<R>>((resolve, reject) => {
      const requestParams = query(params);
      const transformResponse =
        requestParams.transformResponse && !Array.isArray(requestParams.transformResponse)
          ? [requestParams.transformResponse]
          : requestParams.transformResponse;
      axios
        .request({
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
          ...requestParams,
          transformResponse: undefined,
        })
        .then((response) => {
          const data = transformResponse
            ? // @ts-ignore
              transformResponse.reduce(
                (data, func) => func(data, response.headers, response.status),
                response.data,
              )
            : response.data;
          const result = {
            data,
            isLoading: false,
            isFetching: false,
            isError: false,
            error: null,
          };
          setResponseResult(result);
          resolve(result);
        })
        .catch((error) => {
          const result = {
            isLoading: false,
            isFetching: false,
            isError: true,
            data: undefined,
            error,
          };
          setResponseResult(result);
          reject(result);
        });
    });
  };

  return [trigger, responseResult];
};
