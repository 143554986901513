import { FC, PropsWithChildren } from 'react';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import { Box, Button, FormControl, FormLabel, IconButton, Stack, Tooltip } from '@mui/material';

import { styles } from './styles';
import UnPin from '../icons/UnPin';

type Props = Partial<{
  title: string;
  submitLabel: string;
  submitDisabled: boolean;
  className: string;
  hideActions: boolean;
  isPinned: boolean;
  onPinChange: () => void;
  onClose: () => void;
}> & {
  onReset: () => void;
  onSubmit: () => void;
};

export const FiltersSidebar: FC<PropsWithChildren<Props>> = ({
  children,
  className,
  title = 'Фільтри',
  submitLabel = 'Застосувати',
  submitDisabled = false,
  hideActions = false,
  isPinned = false,
  onClose,
  onSubmit,
  onReset,
  onPinChange,
}) => {
  const handleSubmit = () => {
    onSubmit();
  };

  const handlePinChange = () => {
    onPinChange?.();
  };

  return (
    <Box sx={styles.filtersFormWrapper} className={className}>
      {!isPinned && !!onClose && (
        <IconButton sx={styles.closeButton} onClick={onClose} title="Закрити">
          <CloseOutlinedIcon htmlColor="white" />
        </IconButton>
      )}
      <Stack direction="row" sx={styles.sidebarHeading}>
        {!!onPinChange && (
          <IconButton onClick={handlePinChange} color="inherit">
            {isPinned ? (
              <Tooltip arrow title="Відкріпити фільтри" placement="left">
                <UnPin fontSize="small" />
              </Tooltip>
            ) : (
              <Tooltip arrow title="Закріпити фільтри" placement="left">
                <PushPinOutlinedIcon fontSize="small" color="action" />
              </Tooltip>
            )}
          </IconButton>
        )}
        <FormLabel sx={styles.title}>{title}</FormLabel>
        <Tooltip arrow title="Скинути усі фільтри" placement="left">
          <IconButton onClick={onReset} sx={{ ml: 'auto' }}>
            <FilterAltOffOutlinedIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Stack>
      <Box sx={styles.formControlsBox}>{children}</Box>
      {!hideActions && (
        <FormControl sx={styles.filterActionsBox}>
          <Button
            variant="contained"
            color="primary"
            disabled={submitDisabled}
            onClick={handleSubmit}
          >
            {submitLabel}
          </Button>
        </FormControl>
      )}
    </Box>
  );
};
