import { type FC } from 'react';

import { createPortal } from 'react-dom';

import { Alert, Snackbar, type AlertProps } from '@mui/material';

type Props = {
  severity: AlertProps['severity'];
  message: string;
  open: boolean;
  autoHideDuration?: number;
  onClose: () => void;
};

export const Notification: FC<Props> = ({
  severity,
  message,
  open,
  autoHideDuration = 5000,
  onClose,
}) => {
  return createPortal(
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={open}
      onClose={onClose}
      autoHideDuration={autoHideDuration}
      ClickAwayListenerProps={{ onClickAway: () => null }}
    >
      <Alert severity={severity} onClose={onClose} sx={{ width: '100%' }} variant="filled">
        {message}
      </Alert>
    </Snackbar>,
    document.body,
  );
};
