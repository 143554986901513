import { FC, lazy, useContext } from 'react';

import SuspenseWithLoading from 'components/SuspenseWithLoading';
import { AuthContext } from 'contexts/AuthContext';
import { AuthContextType } from 'contexts/AuthContext/types';
import { UserProfileType } from 'enums/account';
import { useAppSelector } from 'store';
import { getUserType } from 'store/slices/account/selectors';

const PrivateRouter = lazy(() => import('./components/PrivateRouter'));
const LoginOnlyRouter = lazy(() => import('./components/LoginOnlyRouter'));

const AppRouter: FC = () => {
  const { isAuthenticated, isFetching } = useContext<AuthContextType>(AuthContext);
  const userType = useAppSelector(getUserType);

  if (isFetching) {
    return null;
  }

  const loginOnlyRoutes = isAuthenticated && userType === UserProfileType.LOGIN_ONLY;

  if (loginOnlyRoutes) {
    return (
      <SuspenseWithLoading>
        <LoginOnlyRouter />
      </SuspenseWithLoading>
    );
  }

  return (
    <SuspenseWithLoading>
      <PrivateRouter />
    </SuspenseWithLoading>
  );
};

export default AppRouter;
