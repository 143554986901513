import { useEffect, useRef } from 'react';

/* 
 use setTimeout to fix behavior: 'smooth' bug
 bug: scrollIntoView() not working as expected in chrome. #23396
 issue: https://github.com/facebook/react/issues/23396
*/

const useRefScrollTo = <T>(dependencies: T, arg?: boolean | ScrollIntoViewOptions) => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!ref.current) return;
      ref.current.scrollIntoView(arg);
    }, 0);

    return () => clearTimeout(timer);
  }, [dependencies]);

  return ref;
};

export default useRefScrollTo;
