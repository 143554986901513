import { FC } from 'react';

import { Dayjs } from 'dayjs';

import { DateTimePicker as MuiDateTimePicker, type DateTimePickerProps } from '@mui/x-date-pickers';

export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm';

export const DateTimePicker: FC<DateTimePickerProps<Dayjs>> = ({
  views = ['year', 'month', 'day', 'hours', 'minutes'] as const,
  format = DATE_TIME_FORMAT,
  ampm = false,
  ...restProps
}) => {
  return <MuiDateTimePicker views={views} format={format} ampm={ampm} {...restProps} />;
};
