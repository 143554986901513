import { useEffect } from 'react';

import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useNavigate } from 'react-router-dom';

import { ERROR_AREA_MESSAGE, ErrorAreas } from 'constants/errors';
import { PUBLIC_ROUTES } from 'constants/publicRoutes';
import { ErrorData } from 'types/api.type';
import { displayErrorMessages } from 'utils';

type ErrorDetails = {
  reason: string;
  details: string;
};

export const useErrorHandling = (
  error: ErrorData | FetchBaseQueryError | unknown,
  errorArea?: ErrorAreas
) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!error) return;

    const errorObj = error as ErrorData;

    if (errorObj.reason && errorObj.statusCode === 404) {
      let errorDetails: ErrorDetails | undefined;

      if (errorArea && ERROR_AREA_MESSAGE[errorArea]) {
        errorDetails = ERROR_AREA_MESSAGE[errorArea];
      }
      const num = errorObj?.extra?.details?.toString().replace(/\D/g, '');

      navigate(PUBLIC_ROUTES.notFound, {
        replace: true,
        state: {
          ...errorObj,
          ...(errorDetails && {
            reason: errorDetails.reason,
            extra: {
              details: num ? `Id: ${num} ${errorDetails.details}` : errorDetails.details,
            },
          }),
        },
      });
    }

    displayErrorMessages(error);
  }, [error]);
};
