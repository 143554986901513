import { forwardRef, PropsWithChildren, ReactNode } from 'react';

import { Controller } from 'react-hook-form';

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  type SelectProps,
} from '@mui/material';

import { FormErrorMessage } from '../FormErrorMessage';

type SelectOption<T extends string | number> = {
  value: T;
  label: string | ReactNode;
};

type Props<T extends string | number> = {
  name: string;
  id?: string;
  label?: string | JSX.Element;
  variant?: SelectProps['variant'];
  size?: SelectProps['size'];
  disabled?: boolean;
  className?: string;
  value?: string | number;
  onChange?: SelectProps['onChange'];
  fullWidth?: boolean;
  selectProps?: SelectProps;
  showErrors?: boolean;
  multipleErrors?: boolean;
} & (
  | { options: SelectOption<T>[]; children?: never }
  | { options?: never; children: PropsWithChildren['children'] }
);

export const FormSelect = forwardRef(
  <T extends string | number>({
    id,
    name,
    label,
    options,
    variant,
    size,
    disabled,
    selectProps,
    children,
    className = '',
    showErrors = true,
    multipleErrors = false,
    fullWidth = true,
    onChange,
  }: Props<T>) => {
    const commonProps = {
      id: id || name,
      name,
      variant,
      label,
      fullWidth,
      className,
      disabled,
    };

    return (
      <Controller
        name={name}
        render={({
          field: { onChange: cOnChange, onBlur: cOnBlur, value: cValue, ref: cRef },
          fieldState: { invalid, error },
        }) => {
          const validationMessage = showErrors ? (
            <FormErrorMessage error={error} multiple={multipleErrors} />
          ) : null;
          return (
            <FormControl
              error={invalid}
              className={className}
              fullWidth
              disabled={disabled}
              size={size}
            >
              {label && <InputLabel htmlFor={name}>{label}</InputLabel>}
              <Select
                {...selectProps}
                value={cValue}
                onChange={(event, child) => {
                  onChange?.(event, child);
                  cOnChange(event);
                }}
                size={size}
                onBlur={cOnBlur}
                inputRef={cRef}
                error={invalid}
                {...commonProps}
              >
                {children}
                {options?.map((option) => (
                  <MenuItem key={`formikSelect-${option.value}`} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {validationMessage && <FormHelperText error>{validationMessage}</FormHelperText>}
            </FormControl>
          );
        }}
      />
    );
  },
);
