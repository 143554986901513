import { FC, ReactElement } from 'react';

import { Chip, chipClasses } from '@mui/material';
import { type SxProps, type Theme, alpha } from '@mui/material/styles';

import { WARNING } from 'contexts/ThemeContext/palette';
import { Styles } from 'types/theme.type';

import { DEFAULT_TAG_PRIORITY } from './constants';
import { getBandTagChipColor } from './utils';

const styles: Styles = {
  tagChip: {
    '&.MuiChip-colorWarning': {
      backgroundColor: alpha(WARNING.darker, 0.5),
      color: 'text.primary',
    },
    [`.${chipClasses.label}`]: {
      whiteSpace: 'nowrap',
      px: 1,
    },
  },
};

type TagProps = {
  name: string;
} & Partial<{
  id: number;
  priority: number;
  disabled: boolean;
  sx: SxProps<Theme>;
  size: 'small' | 'medium';
  deleteIcon: ReactElement;
  onClick: () => void;
  onDelete: () => void;
}>;

export const BandTagChip: FC<TagProps> = ({
  id,
  name,
  size,
  disabled,
  priority = DEFAULT_TAG_PRIORITY,
  sx,
  deleteIcon,
  onDelete,
  onClick,
}) => {
  const chipColor = getBandTagChipColor(priority);

  return (
    <Chip
      key={id}
      label={name}
      color={chipColor}
      size={size}
      disabled={disabled}
      sx={[styles.tagChip, sx] as SxProps<Theme>}
      deleteIcon={deleteIcon}
      data-testid="control-user-tag"
      onClick={onClick}
      onDelete={onDelete}
    />
  );
};
