export enum ErrorAreas {
  PROFILE_ERROR = 'PROFILE',
  SEARCH_ERROR = 'SEARCH',
  REPEAT_SEARCH_ERROR = 'REPEAT_SEARCH',
  BAND_ERROR = 'PERSON',
}

export const ERROR_AREA_MESSAGE = {
  [ErrorAreas.PROFILE_ERROR]: {
    reason: 'Досьє не знайдено',
    details: 'Досьє не знайдено в базі',
  },
  [ErrorAreas.SEARCH_ERROR]: {
    reason: 'Пошук не знайдено',
    details: 'Пошук не знайдений в базі',
  },
  [ErrorAreas.REPEAT_SEARCH_ERROR]: {
    reason: 'Пошук не знайдено',
    details: 'Даний пошук не знайдений в базі',
  },
  [ErrorAreas.BAND_ERROR]: {
    reason: 'Досьє не знайдено',
    details: 'Досьє не знайдено в базі',
  },
};
