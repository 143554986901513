import { decamelizeKeys } from 'humps';

import { api } from 'api';
import { API_BASE_URL, NOMINATIM_API_BASE_URL } from 'constants/url';
import { ErrorData } from 'types/api.type';
import {
  GeocodingParams,
  ConvertCoordinatesParams,
  ConvertCoordinatesResponse,
  GeocodingFeaturesResponse,
} from 'types/geocoding.type';
import { generateLocationName } from 'utils/geo';

const DEFAULT_NOMINATIM_PARAMS = {
  'accept-language': 'uk-UA',
  format: 'geocodejson',
  zoom: 18,
} as const;

const CONVERT_COORDINATES_API_URL = `${API_BASE_URL}/convert_coordinates`;

const geocodingApiHooks = api.injectEndpoints({
  endpoints: (builder) => ({
    getLocationByLatLng: builder.query<string, GeocodingParams>({
      query: (params) => ({
        url: `${NOMINATIM_API_BASE_URL}`,
        params: { ...DEFAULT_NOMINATIM_PARAMS, ...decamelizeKeys(params) },
      }),
      transformResponse(response: GeocodingFeaturesResponse) {
        const { features } = response;
        const geocoding = features?.[0].properties.geocoding;
        if (!geocoding) {
          return 'Місцезнаходження не визначено';
        }
        return generateLocationName(geocoding);
      },
    }),
    convertCoordinates: builder.query<ConvertCoordinatesResponse, ConvertCoordinatesParams>({
      query: (payload) => ({
        url: CONVERT_COORDINATES_API_URL,
        method: 'POST',
        body: payload,
      }),
      transformErrorResponse: (error) => error.data as ErrorData,
    }),
  }),
});

export const {
  useGetLocationByLatLngQuery,
  useLazyGetLocationByLatLngQuery,
  useConvertCoordinatesQuery,
} = geocodingApiHooks;
