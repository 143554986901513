import { type SxProps } from '@mui/material';

import { isDarkMode } from '../../utils/isDarkMode';

export const styles: Record<string, SxProps> = {
  fileSelectComponent: {
    border: '2px dashed',
    borderColor: 'divider',
    overflow: 'hidden',
    padding: 1,
    borderRadius: 2,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    '&.validation-error': {
      borderColor: 'error.main',
    },
    '& .file-select-draggable-zone-overlay, & .file-select-input-overlay': {
      cursor: 'pointer',
      position: 'absolute',
      opacity: 0,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    '& .file-select-draggable-zone-overlay': {
      visibility: 'hidden',
      opacity: 0,
      transition: 'visibility 0.1s, opacity 0.1s linear',
      display: 'flex',

      backgroundColor: 'text.primary',
      backdropFilter: 'blur(10px)',
      color: isDarkMode('common.black', 'common.white'),
      flexShrink: 0,
      alignItems: 'center',
      textAlign: 'center',
      '& > span': { flex: '0 0 100%' },
    },
    '&.file-select-dragging': {
      '& .file-select-draggable-zone-overlay': {
        opacity: 0.3,
        visibility: 'visible',
      },
    },
    '&:focus-within': {
      borderColor: 'primary.main',
      borderStyle: 'solid',
    },
  },
  bottomFilePreviewList: {
    paddingTop: 2,
  },
  contentPreviewList: {
    minHeight: 98,
  },
  fileSelectActions: {
    display: 'flex',
    gap: 1,
    marginTop: 'auto',
    alignSelf: 'end',
  },
  dragZoneInput: {
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
};
