import { FC, ComponentProps, HTMLInputTypeAttribute, ReactElement } from 'react';

import { Box } from '@mui/material';

import FormikCheckbox from 'components/FormikCheckbox';
import FormikPasswordTextField from 'components/FormikPasswordTextField';
import FormikTextField from 'components/FormikTextField';
import { Styles } from 'types/theme.type';

const styles: Styles = {
  formField: { minHeight: '40px' },
  formFieldHelperText: { px: 2 },
};

const defaultTextFieldProps: Partial<ComponentProps<typeof FormikTextField>> = {
  fullWidth: true,
  size: 'small',
};

const renderField = (type: HTMLInputTypeAttribute | undefined, propsRest: Props) => {
  switch (type) {
    case 'checkbox':
      return <FormikCheckbox size="small" name={propsRest.name} label={propsRest.label ?? ''} />;
    case 'password':
      return <FormikPasswordTextField {...defaultTextFieldProps} {...propsRest} />;
    default:
      return <FormikTextField {...defaultTextFieldProps} {...propsRest} />;
  }
};

type Props = Omit<ComponentProps<typeof FormikTextField>, 'label'> & {
  label?: string | ReactElement;
};

const PromptDialogField: FC<Props> = ({ type, ...propsRest }) => {
  return <Box sx={styles.formField}>{renderField(type, propsRest)}</Box>;
};

export default PromptDialogField;
